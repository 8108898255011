import React from "react";
import { MyNavBt } from "../elements/button/MyNavBt";
import { LogoComp } from "./LogoComp";
import PageComponentProps from "interfaces/pagecomponentprops.interface";

const TopLogoBlock: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {

  
  return <MyNavBt href={"/"}  >
    <LogoComp />
    {/* {ComponentCommonTools.getFileBlock(CF("logo"),50,50)}
    {ComponentCommonTools.getFileBlock(CF("logomini"),50,50)} */}
    </MyNavBt>;
};

export { TopLogoBlock };
