// import RequestListDTO from "dto/app/requestlist.dto";
// import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
// import ResultObjectDTO from "dto/app/resultobject.dto";

// import { logger } from "tools/utils/logger";
// import IService from "interfaces/iservice.interface";
// import {SupportArticleRatingRepository} from "repositories/static/supportarticlerating.repository";
// import { Status } from "tools/types/status";
// import GeneralService from "services/general.service";
// import ResultListDTO from "dto/app/resultlist.dto";

// export default class SupportArticleRatingService
//   extends GeneralService
//   implements IService
// {
//   supportarticleratingRepository: SupportArticleRatingRepository =
//     new SupportArticleRatingRepository();
//   constructor() {
//     super();
//     this.handleGetList = this.handleGetList.bind(this);
//     this.handleGet = this.handleGet.bind(this);
//   }
//   handleUpdate(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;

//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   async update(
//     id: string,
//     cb?: any,
//     cbparameters?: any,
//     data?: any
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.supportarticleratingRepository.update(
//       id,
//       this.handleUpdate,
//       data,
//       cbparameters
//     );
//   }

//   handleDelete(result?: any, cbparameters?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters);
//     }
//   }

//   async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;

//     this.supportarticleratingRepository.delete(
//       id,
//       this.handleDelete,
//       cbparameters
//     );
//   }

//   handleGet(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }

//     return rez;
//   }

//   async get(id: string, cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.supportarticleratingRepository.get(
//       id,
//       this.handleGet,
//       cbparameters
//     );
//     if (cb == undefined) {
//       return this.handleGet(t);
//     }
//   }

//   async getRating(
//     id: string,
//     idUser: string,
//     cb?: any,
//     cbparameters?: any
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.supportarticleratingRepository.getRating(
//       id,
//       idUser,
//       this.handleGet,
//       cbparameters
//     );
//     if (cb == undefined) {
//       return this.handleGet(t);
//     }
//   }

//   handleGetList(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultListDTO();

//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }

//     return rez;
//   }

//   async getList(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.supportarticleratingRepository.getList(
//       this.handleGetList,
//       cbparameters,
//       data
//     );
//     if (cb == undefined) {
//       return this.handleGetList(t);
//     }
//   }

//   handleAdd(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   async add(cb?: any, cbparameters?: any, data?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.supportarticleratingRepository.add(
//       this.handleGetList,
//       cbparameters,
//       data
//     );
//   }

//   async like(cb?: any, cbparameters?: any, data?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.supportarticleratingRepository.like(
//       this.handleAdd,
//       cbparameters,
//       data
//     );
//   }

//   async dislike(cb?: any, cbparameters?: any, data?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.supportarticleratingRepository.dislike(
//       this.handleAdd,
//       cbparameters,
//       data
//     );
//   }

//   // getDefaultRequestListSelect(onPage?: number): RequestListDTO {
//   //   onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
//   //   if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
//   //   var defaultRequestList = new RequestListDTO();
//   //   defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
//   //   defaultRequestList.onpage = onPage;
//   //   var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
//   //   defaultRequestSortCriteria.asc = true;
//   //   defaultRequestSortCriteria.field = 'identifier';
//   //   defaultRequestList.sortcriteria = []
//   //   defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
//   //   return defaultRequestList;
//   // }

//   getDefaultRequestList(obj: RequestListDTO): RequestListDTO {
//     if (
//       !obj.sortcriteria ||
//       !Array.isArray(obj.sortcriteria) ||
//       !obj.sortcriteria.length
//     ) {
//       var sobj = new RequestSortCriteriaDTO();
//       sobj.asc = true;
//       sobj.field = "supportarticlerating";

//       obj.sortcriteria = [];
//       obj.sortcriteria?.push(sobj);
//     }

//     return obj;
//   }
// }

import GeneralService from "services/globalgeneral.service";
import IService from "interfaces/iservice.interface";
import { SupportArticleRatingRepository } from "repositories/static/supportarticlerating.repository";

export class SupportArticleRatingService
  extends GeneralService<SupportArticleRatingRepository>
  implements IService
{
  constructor() {
    super(new SupportArticleRatingRepository(), [], "identifier");
  }

  async getRating(
    id: string,
    idUser: string,
    cb?: any,
    cbparameters?: any
  ): Promise<any> {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.getRating(
      id,
      idUser,
      this.handleGet,
      cbparameters
    );
    if (cb === undefined) {
      return this.handleGet(t);
    }
  }

  like = async (cb?: any, cbparameters?: any, data?: any): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.like(this.handleGet, cbparameters, data);
    if (cb === undefined) {
      return this.handleGet(t);
    }
  };

  dislike = async (cb?: any, cbparameters?: any, data?: any): Promise<any> => {
    cbparameters = cbparameters ? cbparameters : {};
    cbparameters._cb = cb;
    const t = await this.repository.dislike(this.handleGet, cbparameters, data);
    if (cb === undefined) {
      return this.handleGet(t);
    }
  };
}
