import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { StaticInfoPage } from "components/static/page/block/StaticInfoPage";

const NotFound: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {
  return (
    <StaticInfoPage
      idPage={process.env.REACT_APP_ID_PAGE_404}
      cacheKeyName="page_404"
    />
  );
};

export { NotFound };
