import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";

import { UserTopMenu } from 'components/user/UserTopMenu'

const TopBar: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {
    return <UserTopMenu currentRoute={currentRoute} />
    
};

export { TopBar };
