import React, { useEffect, useState } from "react";

import { useResource } from "hooks/useResource";
import { VideoDto } from "dto/system/video.dto";

import RequestListDTO from "dto/app/requestlist.dto";

import { VideoService } from "services/system/video.service";
import RequestFilterDTO from "dto/app/requestfilter.dto";

import { Status } from "tools/types/status";
import { Types } from "tools/types/types";
import { VideoListItem } from "./VideoListItem";
import { CommonTools } from "tools/utils/commontools";
import { Carousel } from "components/elements/Carousel/Carousel";
import { SkeletonCarousel } from "components/elements/Skeleton/Skeleton";
const videoService = new VideoService();
type VideoListProps = {
  parent: string;
  typeDisplay?: number;
};
const VideoListCarousel: React.FC<VideoListProps> = ({
  parent,
  typeDisplay = Types.TYPE_VIDEO_LIST_ITEM_DISPLAY_CAROUSEL,
  ...props
}) => {
  const { CC } = useResource();
  const [loading, setLoading] = useState<boolean>(false);
  const [objects, setObjects] = useState<Array<VideoDto>>([]);
  const { saveCache, getCache } = useResource();

  const getList = async () => {
    const reqList = new RequestListDTO();
    reqList.onpage = parseInt(CC("tOnPageVideoListCarousel", "10"));
    reqList.page = 1;
    reqList.filters = [];
    reqList.sortcriteria = [];

    let f: RequestFilterDTO;

    f = new RequestFilterDTO();
    f.field = "status";
    f.values = [];
    f.values.push(Status.ACTIVE.toString());
    reqList.filters.push(f);

    f = new RequestFilterDTO();
    f.field = "parent";
    f.values = [parent];
    reqList.filters.push(f);
    setLoading(true);

    const cd = getCache(JSON.stringify(reqList));

    if (cd !== false) {
      setObjects(cd);
      setLoading(false);
      return;
    }

    const data = await videoService.getList(undefined, {}, reqList);

    const rez = !data || !data.objects ? [] : data.objects;

    setObjects(rez);
    setLoading(false);

    saveCache(rez, JSON.stringify(reqList));
  };

  useEffect(() => {
    getList();
  }, [parent]);

  const processItem = (item: VideoDto, i: number) => {
    if (!item) return null

    return <VideoListItem index={i} key={i} obj={item} typeDisplay={typeDisplay} />;
  };
  const processLessonList = () => {
    if (loading) return <SkeletonCarousel />;
    if (!objects.length) return null
    const slidesToShow = CommonTools.calculateNumberOfElementsShowOnCarousel(
      parseInt(CC("DefaultOnPageVideoListCarousel", "4")),
      objects.length
    );
    return (
      <Carousel _slidesToShow={slidesToShow}>
        {objects.map((item: VideoDto, i: number) => {
          return processItem(item, i);
        })}
      </Carousel>
    );
  };
  return processLessonList();
};

export { VideoListCarousel };
