import { VideoDto } from "dto/system/video.dto";
import React from "react";
import { Types } from "tools/types/types";
import { VideoListItemPlayerDisplay } from "./VideoListItemPlayerDisplay";
import { VideoListItemTextualDisplay } from "./VideoListItemTextualDisplay";
import { VideoListItemCarouselDisplay } from "./VideoListItemCarouselDisplay";

type Props = {
  obj: VideoDto;
  typeDisplay: number;
  index: number;
};

const VideoListItem: React.FC<Props> = ({ obj, typeDisplay, index }) => {
  const processItem = () => {
    if (!obj) return null;

    if (typeDisplay === Types.TYPE_VIDEO_LIST_ITEM_DISPLAY_PLAYER) {
      return <VideoListItemPlayerDisplay obj={obj} index={index} />;
    }
    if (typeDisplay === Types.TYPE_VIDEO_LIST_ITEM_DISPLAY_TEXTUAL) {
      return <VideoListItemTextualDisplay obj={obj} index={index} />;
    }
    if (typeDisplay === Types.TYPE_VIDEO_LIST_ITEM_DISPLAY_CAROUSEL) {
      return <VideoListItemCarouselDisplay obj={obj} index={index} />;
    }

    return null;
  };

  return processItem();
};

export { VideoListItem };
