import React, { useState, useEffect } from "react";
import { FAQDto } from "dto/static/faq.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { CommonTools } from "tools/utils/commontools";
import { useResource } from "hooks/useResource";

import { FAQService } from "services/static/faq.service";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { Status } from "tools/types/status";

import ResultListDTO from "dto/app/resultlist.dto";
import { Box, Grid, List, ListItem, Skeleton, Typography } from "@mui/material";

import { FAQItem } from "./FAQItem";

import { MyButton } from "components/elements/button/MyButton";
import { FAQItemMenu } from "./FAQItemMenu";
import { MyBreadcrumbs } from "components/elements/MyBreadcrumbs/MyBreadcrumbs";
import { ContactBlock } from "components/support/support/ContactBlock";

const faqService = new FAQService();

type FAQListProps = {
  currentRoute: any;
};
const FAQList: React.FC<FAQListProps> = ({
  currentRoute,

  ...props
}) => {
  const { LL, CC } = useResource();

  const [isLoading, setIsLoading] = useState(true);
  const [objects, setObjects] = useState<Array<FAQDto>>([]);

  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(-1);
  const [totalPage, setTotalPage] = useState<number>(-1);
  const [responseParams, setResponseParams] = useState<any>({});
  const [onPage, setOnPage] = useState<number>(
    parseInt(CC("FAQList", "99999"))
  );
  const [reqList, setReqList] = useState<RequestListDTO>(new RequestListDTO());

  const studentIdTypeBlogCategory = process.env
    .REACT_APP_DEFAULT_ID_TYPE_BLOG_CATEGORY
    ? process.env.REACT_APP_DEFAULT_ID_TYPE_BLOG_CATEGORY
    : "";
  const teacherIdTypeBlogCategory = process.env
    .REACT_APP_DEFAULT_ID_TYPE_BLOG_CATEGORY_TEACHER
    ? process.env.REACT_APP_DEFAULT_ID_TYPE_BLOG_CATEGORY_TEACHER
    : "";

  const processTypeCategoryHState = () => {
    const hStateIdType = CommonTools.getCurrentRouteHistoryState(
      currentRoute,
      "idTypeBlogCategory"
    );
    if (!hStateIdType) return studentIdTypeBlogCategory;
    return hStateIdType;
  };

  const [idTypeBlogCategory, setIdTypeBlogCategory] = useState<string>(
    processTypeCategoryHState()
  );

  const processIdFAQHState = () => {
    const hStateIdType = CommonTools.getCurrentRouteHistoryState(
      currentRoute,
      "idFAQ"
    );
    if (!hStateIdType) return "";
    return hStateIdType;
  };
  const [currentIdFAQ, setCurrentIdFAQ] = useState<string>(
    processIdFAQHState()
  );
  const getList = () => {
    reqList.onpage = onPage;
    reqList.page = page;
    reqList.filters = [];

    let f: RequestFilterDTO;

    f = new RequestFilterDTO();
    f.field = "status";
    f.values = [];
    f.values.push(Status.ACTIVE.toString());
    reqList.filters.push(f);

    f = new RequestFilterDTO();
    f.field = "idtypeblogcategory";
    f.values = [idTypeBlogCategory];
    reqList.filters.push(f);

    setIsLoading(true);
    faqService.getList(loadObjects, {}, reqList);
  };

  const processTypeCategory = () => {
    if (studentIdTypeBlogCategory === idTypeBlogCategory) {
      return (
        <Box>
          <Typography>{LL("FAQ_Student")}</Typography>
        </Box>
      );
    }
    if (teacherIdTypeBlogCategory === idTypeBlogCategory) {
      return (
        <Box>
          <Typography>{LL("FAQ_Teacher")}</Typography>
        </Box>
      );
    }
  };

  const handleSwitchCategory = () => {
    if (studentIdTypeBlogCategory === idTypeBlogCategory) {
      setIdTypeBlogCategory(teacherIdTypeBlogCategory);
    } else {
      setIdTypeBlogCategory(studentIdTypeBlogCategory);
    }
  };

  const processTextSwitchCategory = () => {
    if (studentIdTypeBlogCategory === idTypeBlogCategory) {
      return LL("FAQ_Teacher");
    } else {
      return LL("FAQ_Student");
    }
  };
  const processButtonSwitchCategory = () => {
    return (
      <Box>
        <MyButton cb={handleSwitchCategory}>
          {processTextSwitchCategory()}
        </MyButton>
      </Box>
    );
  };

  const loadObjects = (result: ResultListDTO) => {
    if (!result) return;
    if (!result.err) {
      const objects = result.objects ? result.objects : [];
      const total = result.total !== undefined ? result.total : -1;
      const totalPage =
        result.totalpages !== undefined ? result.totalpages : -1;
      const reqInfo = result.requestinfo ? result.requestinfo : {};
      setObjects(objects);
      setTotal(total);
      setTotalPage(totalPage);
      setResponseParams(reqInfo);
    }
  };

  const checkLoading = () => {
    let load = false;

    if (total === -1) return true;
    if (totalPage === -1) return true;
    if (!objects) load = true;
    // if (objects.length === 0) load = true;
    if (Object.keys(responseParams).length === 0) return true;

    setIsLoading(load);
  };

  useEffect(() => {
    getList();
  }, [idTypeBlogCategory]);

  useEffect(() => {
    checkLoading();
  }, [objects]);

  const processItemMenu = (obj: FAQDto, index: number) => {
    if (!obj) return <></>;

    return (
      <ListItem key={index}>
        <FAQItemMenu
          obj={obj}
          idCurrent={currentIdFAQ}
          setIdCurrent={setCurrentIdFAQ}
        />
      </ListItem>
    );
  };

  const processListMenu = () => {
    if (isLoading) return <Skeleton variant="rectangular" />;
    if (!objects.length) return <></>;

    return (
      <List>
        {objects.map((item: FAQDto, i: number) => {
          return processItemMenu(item, i);
        })}
      </List>
    );
  };

  const processList = () => {
    if (isLoading) return <Skeleton variant="rectangular" />;
    if (!objects.length) return <></>;

    return (
      <Box>
        {objects.map((item: FAQDto, i: number) => {
          return processItem(item, i);
        })}
      </Box>
    );
  };

  const processItem = (obj: FAQDto, index: number) => {
    if (!obj) return <></>;

    return (
      <Box key={index}>
        <FAQItem obj={obj} />
      </Box>
    );
  };

  const ChildsCrumbs = [
    { url: "/", label: LL("crumb_homepage") },
    {
      label: LL("crumb_faq"),
    },
  ];

  const processComponent = () => {
    return (
      <Box className="pageGeneral">
        <Box className="onlyBread" py={3}>
          <Box className="container">
            <MyBreadcrumbs childs={ChildsCrumbs} mainObject={"faq"} />
          </Box>
        </Box>

        <Grid container spacing={2} mt={4}>
          <Grid item sm={12} lg={9}>
            {processList()}
          </Grid>
          <Grid item sm={12} lg={3}>
            {processTypeCategory()}
            {processListMenu()}
            {processButtonSwitchCategory()}
            <ContactBlock currentRoute={currentRoute} />
          </Grid>
        </Grid>
      </Box>
    );
  };

  return processComponent();
};

export { FAQList };
