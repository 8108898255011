import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useReactPath } from "hooks/useReactPath";
import { RouteTools } from "tools/utils/routetools";

import { useResource } from "hooks/useResource";
import { Login } from "pages/authentication/Login";
import { NotFound } from "../../pages/general/NotFound";
import { Forbidden } from "../../pages/general/Forbidden";
import { HomePage } from "../../pages/general/HomePage";
import { Header } from "./Header";
import { Footer } from "./Footer";

import { ForgotPassword } from "pages/authentication/ForgotPassword";

import { ChangeTemporaryPassword } from "components/auth/ChangeTemporaryPassword";

import { PageStatic } from "components/static/page/content/PageStatic";

import { BlogBlock } from "components/static/blog/BlogBlock";
import { BlogStatic } from "components/static/blog/BlogStatic";

import { Loading } from "../elements/loading/Loading";

import { FAQList } from "components/static/faq/FAQList";
import { SupportArticleBlock } from "components/support/supportarticle/SupportArticleBlock";
import { SupportArticleCategoryBlock } from "components/support/supportarticlecategoryblock/SupportArticleCategoryBlock";
import { SupportArticleSearchBlock } from "components/support/supportarticlesearch/SupportArticleSearchBlock";

const MainLayout: React.FC = () => {
  const { currentRoute, setCurrentRoute } = useResource();
  const location = useLocation();
  const pathObject = useReactPath();

  const [_currentpath, setCurrentPath] = React.useState("");
  const [_mainobject, setMainObject] = React.useState("");

  const processPathObject = async () => {
    var routeobj = await RouteTools.parseLocation(location);

    routeobj.historystate = pathObject.historystate;

    if (routeobj.url === _currentpath) return;
    setCurrentPath(routeobj.url);

    setCurrentRoute(routeobj);
  };

  useEffect(() => {
    processPathObject();
  }, [pathObject]);

  useEffect(() => {
    if (currentRoute && currentRoute._mainobject) {
      setMainObject(currentRoute._mainobject);
    }
  }, [currentRoute]);

  // ----------------------------------

  const processStandard = () => {
    if (!_mainobject) return false;

    switch (_mainobject) {
      case "homepage":
        return <HomePage currentRoute={currentRoute} />;
      case "login":
        return <Login currentRoute={currentRoute} />;
      case "changetemporarypassword":
        return <ChangeTemporaryPassword currentRoute={currentRoute} />;
      case "faq":
        return <FAQList currentRoute={currentRoute} />;
      case "forgotpassword":
        return <ForgotPassword currentRoute={currentRoute} />;
      case "forbidden":
        return <Forbidden currentRoute={currentRoute} />;
      case "notfound":
        return <NotFound currentRoute={currentRoute} />;
      default:
        return false;
    }
    // if (_mainobject === "homepage")
    //   return <HomePage currentRoute={currentRoute} />;

    // if (_mainobject === "login") return <Login currentRoute={currentRoute} />;


    // if (_mainobject === "changetemporarypassword")
    //   return <ChangeTemporaryPassword currentRoute={currentRoute} />;

    // if (_mainobject === "faq") return <FAQList currentRoute={currentRoute} />;

    // if (_mainobject === "forgotpassword")
    //   return <ForgotPassword currentRoute={currentRoute} />;

    // if (_mainobject === "forbidden")
    //   return <Forbidden currentRoute={currentRoute} />;

    // if (_mainobject === "notfound")
    //   return <NotFound currentRoute={currentRoute} />;

    // return false;
  };

  const processPage = () => {
    if (!_mainobject) return false;

    if (_mainobject === "page") {
      return <PageStatic currentRoute={currentRoute} />;
    }

    return false;
  };

  const processBlog = () => {
    if (!_mainobject) return false;

    if (_mainobject === "blog") {
      return <BlogBlock currentRoute={currentRoute} />;
    }

    if (_mainobject === "blogpage") {
      return <BlogStatic currentRoute={currentRoute} />;
    }
    return false;
  };

  const processSupportArticle = () => {
    if (!_mainobject) return false;

    if (_mainobject === "supportarticlepage") {
      return <SupportArticleBlock currentRoute={currentRoute} />;
    }

    if (_mainobject === "supportarticlesearch") {
      return <SupportArticleSearchBlock currentRoute={currentRoute} />;
    }
    return false;
  };

  const processSupportArticleCategory = () => {
    if (!_mainobject) return false;

    if (_mainobject === "supportarticlecategory") {
      return <SupportArticleCategoryBlock currentRoute={currentRoute} />;
    }
    return false;
  };

  const processMainObject = () => {
    let t: any = false;

    t = processStandard();
    if (t) return t;

    t = processPage();
    if (t) return t;

    t = processBlog();
    if (t) return t;

    t = processSupportArticle();
    if (t) return t;

    t = processSupportArticleCategory();
    if (t) return t;

    return <Loading />;
  };

  const processMainLayout = () => {
    return (
      <>
        <header>
          <div className="container">
            <Header currentRoute={currentRoute} />
          </div>
        </header>
        <main>{processMainObject()}</main>
        <footer>
          <Footer currentRoute={currentRoute} />
        </footer>
      </>
    );
  };

  return processMainLayout();
};

export { MainLayout };
