import React, { useCallback, useLayoutEffect } from "react";

import { useEffect, useState } from "react";

import { CookiesProvider } from "react-cookie";

import { ResourceProvider } from "providers/ResourceProvider";


import { HeadProvider } from "providers/HeadProvider";
import theme from "assets/themes/theme";

import { ThemeProvider } from "@mui/material/styles";
import { MessageProvider } from "providers/MessageProvider";
import { AppRoutes } from "providers/AppRoutes";
import { SupportArticleProvider } from "context/supportarticle.context";
import GeneralRepository from "repositories/general.repository";
import { ServerError } from "components/general/ServerError";
import { MyGoogleOAuthProvider } from "providers/MyGoogleOAuthProvider";
import { DownloadProvider } from "providers/DownloadProvider";
import { RouteTools } from "tools/utils/routetools";
import { LocalStorageApi } from "api/localstorage.api";
import { JWTProvider } from "providers/JWTProvider";
import { UserProvider } from "providers/UserProvider";

function App() {
  const [_theme, setTheme] = useState({});
  const [serverError, setServerError] = useState<boolean>(false);

  useEffect(() => {
    setTheme(theme);
  }, []);

  const handleReload = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const processServerError = () => {
    setServerError(true);
  };

  const isServerError = useCallback(() => {
    return serverError;
  }, [serverError]);

  const handleUnauthorized = () => {
    handleReload();
    LocalStorageApi.saveValue("token", "");
    LocalStorageApi.saveValue("remember_token", "");
    RouteTools.setHistory("/login", {});
  };

  const handleForbidden = () => {
    handleReload();
    RouteTools.setHistory("/forbidden", {});
  };

  useLayoutEffect(() => {
    GeneralRepository.setReloadFunction(handleReload);
    GeneralRepository.setServerError(processServerError);
    GeneralRepository.setHandleUnauthorized(handleUnauthorized);
    GeneralRepository.setForbiddenFunction(handleForbidden);
  }, []);

  useEffect(() => {
    GeneralRepository.setIsServerError(isServerError);
  }, [isServerError]);

  if (serverError) {
    return (
      <ThemeProvider theme={_theme}>
        <ServerError />
      </ThemeProvider>
    );
  }
  return (
    <MyGoogleOAuthProvider>
      <ThemeProvider theme={_theme}>
        <CookiesProvider>
          <HeadProvider>
            <JWTProvider>
              <UserProvider>
                <ResourceProvider>
                  <MessageProvider>
                    <SupportArticleProvider>
                      <DownloadProvider>
                        <AppRoutes />
                      </DownloadProvider>
                    </SupportArticleProvider>
                  </MessageProvider>
                </ResourceProvider>
              </UserProvider>
            </JWTProvider>
          </HeadProvider>
        </CookiesProvider>
      </ThemeProvider>
    </MyGoogleOAuthProvider>
  );
}

export default App;
