import React, { useContext } from "react";
import { useResource } from "hooks/useResource";

import Skeleton from "@mui/material/Skeleton";
import { List, Typography, Box } from "@mui/material";
import { SupportArticleContext } from "context/supportarticle.context";
import { SupportArticleCategoryDto } from "dto/support/supportarticlecategory.dto";
import { Types } from "tools/types/types";
import { SupportArticleCategoryMenuItem } from "./SupportArticleCategoryMenuItem";
type Props = {
  mainCode?: string;
};
const SupportArticleCategoryMenuList: React.FC<Props> = ({ mainCode }) => {
  const { LL } = useResource();
  const {
    state: {
      articleSupportCategoryHomeObjects,
      isLoadingHomePageArticleCategory,
      typeCategory,
    },
  } = useContext(SupportArticleContext);

  const processItem = (obj: SupportArticleCategoryDto, index: number) => {
    if (!obj) return null;

    return (
      <SupportArticleCategoryMenuItem
        obj={obj}
        index={index}
        mainCode={mainCode}
        key={index}
      />
    );
  };

  const processList = () => {
    if (!articleSupportCategoryHomeObjects.length) return null;

    return (
      <Box>
        <Typography variant="h5" my={2}>
          {LL("HomeBlogCategoryList_For") + "_" + Types.LL(typeCategory)}
        </Typography>

        <List>
          {articleSupportCategoryHomeObjects.map(
            (item: SupportArticleCategoryDto, i: number) => {
              return processItem(item, i);
            }
          )}
        </List>
      </Box>
    );
  };

  return isLoadingHomePageArticleCategory ? <Skeleton /> : processList();
};

export { SupportArticleCategoryMenuList };
