import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hooks/useResource";

import { useAuth } from "hooks/useAuth";

import { MyNavBt } from "components/elements/button/MyNavBt";

const SignInBlock: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  const { LL } = useResource();
  const { user } = useAuth();
  return user ? null : (
    <MyNavBt sx={{ ml: 2 }} className="btnTop btnSignInTop" href={"/login"}>
      {LL("BT_SignInTop")}
    </MyNavBt>
  );
};

export { SignInBlock };
