import { Avatar } from "@mui/material";
import { useResource } from "hooks/useResource";
import React from "react";
import { CommonTools } from "tools/utils/commontools";
import { FileTools } from "tools/utils/filetools";

type MyAvatarProps = {
  [x: string]: any;
  name: string;
  surname: string;
  obj: any;
};

const MyAvatar: React.FC<MyAvatarProps> = ({
  name,
  surname,
  obj,
  ...props
}) => {
  const { CC } = useResource();

  const processAvatarName = () => {
    if (!obj) return CC("AvatarDefaultSiteAcronym", "LC");
    if (!name) return CC("AvatarDefaultSiteAcronym", "LC");
    if (!surname) return name.charAt(0).toUpperCase();

    return name.charAt(0).toUpperCase() + surname.charAt(0).toUpperCase();
  };

  const getAvatarUrl = () => {
    if (!obj) return "";
    if (!CommonTools.processObjectField(obj, ["idavatar"])) return "";

    return FileTools.getFileUrl(
      CommonTools.processObjectField(obj, ["idavatar"])
    );
  };

  const processAvatar = () => {
    const url = getAvatarUrl();
    if (!url) return <Avatar {...props}>{processAvatarName()}</Avatar>;

    return <Avatar {...props} src={url} />;
  };

  return processAvatar();
};

export { MyAvatar };
