import React, { useContext } from "react";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { Box, Typography } from "@mui/material";
import { SupportArticleDto } from "dto/support/supportarticle.dto";
import { MyNavBt } from "components/elements/button/MyNavBt";
import { useResource } from "hooks/useResource";
import { SupportArticleContext } from "context/supportarticle.context";

type Props = {
  item: SupportArticleDto;
};
const SupportArticlesSearchItem: React.FC<Props> = ({ item }) => {
  const { LL } = useResource();
  const {
    actions: { setTypeCategory },
  } = useContext(SupportArticleContext);
  const processUrl = () => {
    if (!item) return "";
    if (!item.hasOwnProperty("fullurl")) return "";
    if (!item.fullurl) return "";
    return item.fullurl;
  };
  const processTypeCategory = () => {
    if (!item) return;
    if (!item.hasOwnProperty("type")) return;
    if (!item.type) return;
    setTypeCategory(item.type);
  };
  return (
    <Box my={3}>
      <Box>
        <Typography className="title-support">
          {ComponentCommonTools.processObjectField(item, "name")}
        </Typography>
      </Box>
      <Box>
        <Typography my={1}>
          {ComponentCommonTools.processObjectField(item, "shortdescription")}
        </Typography>
      </Box>
      <Box>
        <MyNavBt href={processUrl()} className="link-classic" additionalOnClick={processTypeCategory}>
          {LL("Read_article")}
        </MyNavBt>
      </Box>
    </Box>
  );
};

export { SupportArticlesSearchItem };
