import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hooks/useResource";

import { MyNavBt } from "components/elements/button/MyNavBt";
import { useAuth } from "hooks/useAuth";

const SignUpBlock: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  const { LL, urlToSignUp } = useResource();

  const { user } = useAuth();

  return user ? null : (
    <MyNavBt
      href={urlToSignUp}
      className="btnTop btnSignUpTop"
      target="_blank"
      rel="noopener noreferrer"
    >
      {LL("BT_SignUpTop")}
    </MyNavBt>
  );
};

export { SignUpBlock };
