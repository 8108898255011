import { useEffect, useState, useCallback, useMemo } from "react";
import RequestListDTO from "../dto/app/requestlist.dto";
import ResultListDTO from "../dto/app/resultlist.dto";
import { useResource } from "./useResource";


type UseListType = <T>(
  getListFun: (cb: any, cbParams: any, reqList: RequestListDTO) => void,
  requestList: RequestListDTO,
  dependencies: Array<any>,
  useCache?: boolean,
  cacheIdentifier?: string
) => [boolean, T[] | null, number, number];
var isFetching = false;
const useList: UseListType = <T,>(
  getListFun: (cb: any, cbParams: any, reqList: RequestListDTO) => void,
  requestList: RequestListDTO,
  dependencies: Array<any>,
  useCache = false,
  cacheIdentifier = ""
) => {
  const [objects, setObjects] = useState<T[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [total, setTotal] = useState<number>(-1);
  const [totalPages, setTotalPages] = useState<number>(-1);
  const { saveCache, getCache } = useResource();

  const getList = useCallback(() => {
    if (useCache && cacheIdentifier) {
      const cache = getCache(cacheIdentifier);
      if (cache) {
        setObjects(cache.objects);
        setTotal(cache.total);
        setTotalPages(cache.totalpages);

        return;
      }
    }
    // if(isFetching) return;
    isFetching = true;
    setLoading(true);
    const reqList = requestList;
    getListFun(handelGetList, {}, reqList);
  }, dependencies);

  const handelGetList = (result: ResultListDTO) => {
    if (result.err) return;
    const objects: T[] = (result.objects as T[]) ?? [];
    const total = result.total ?? 0;
    const totalPages = result.totalpages ?? 0;
    setObjects(objects);
    setTotal(total);
    setTotalPages(totalPages);
    if (useCache && cacheIdentifier) saveCache(result, cacheIdentifier);
    isFetching = false;
  };

  useEffect(() => {
    getList();
  }, [getList]);

  const checkLoading = useCallback(() => {
    let loading = false;
    if (objects === null) loading = true;
    if (total === -1) loading = true;
    if (totalPages === -1) loading = true;
    
    setLoading(loading);
  }, [objects, total, totalPages]);

  useEffect(() => {
    checkLoading();
  }, [checkLoading]);

  return useMemo(
    () => [loading, objects, total, totalPages],
    [loading, objects, total, totalPages]
  );
};

export { useList };
