import Idto from "interfaces/idto.interface";
import MessageDto from "dto/app/message.dto";
import UserDto from "dto/user/user.dto";
import { AccessTokenDto } from "dto/auth/accesstoken.dto";
import UserSettingsDto from "dto/user/usersettings.dto";
import { StudentDto } from "dto/user/student.dto";
import { TeacherDto } from "dto/user/teacher.dto";

export default class ResultSignInDTO implements Idto {
  err?: boolean;
  mustchangepassword?: boolean;
  accesstoken?: AccessTokenDto;
  messages?: MessageDto[];
  obj?: UserDto;
  roles?: string[];
  usersettings?: UserSettingsDto;
  student?:StudentDto
  teacher?:TeacherDto
}
