import  React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Unstable_Grid2";
import { useResponsive } from "hooks/useResponsive";

const SkeletonFooter: React.FC = () => {
    const {  matchesSM } = useResponsive();
  
    let gridItemsCount;
    if (matchesSM) {
      gridItemsCount = 3;
    } else {
      gridItemsCount = 12;
    
    }
    const skeletonHeight = matchesSM ? "110px" : "470px";
  
    const gridItem = (
      <Grid xs={gridItemsCount}>
          <Grid xs={12} >
            <Skeleton variant="text" sx={{ fontSize: "1rem", width: "70%" }} />
            <Skeleton variant="text" sx={{ fontSize: "1rem",  width: "50%" }} />
            <Skeleton variant="text" sx={{ fontSize: "1rem", width: "60%" }} />
            <Skeleton variant="text" sx={{ fontSize: "1rem", width: "70%" }} />
          </Grid>
      </Grid>
    );
  
    return (
      <Box sx={{ width: "100%", height: skeletonHeight }} className="">
        <Box
          sx={{
            width: "90%",
            height: skeletonHeight,
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            margin: "auto",
          }}
        >
          <Grid
            container
            xs={12}
            sx={{ maxWidth: "1522px", margin: "auto" }}
            spacing={2}
          >
             {Array(4)
              .fill(null)
              .map((_, index) => (
                <React.Fragment key={index}>{gridItem}</React.Fragment>
              ))}
          </Grid>
        </Box>
      </Box>
    );
  };


export { SkeletonFooter};
