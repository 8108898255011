import SelectOptions from "dto/app/selectoptions.dto";
import Idto from "interfaces/idto.interface";
import { CommonTools } from "tools/utils/commontools";

export class TicketCategoryDto implements Idto {
  id?: string;
  idlanguage?: string;
  status?: number;
  ordercriteria?: number;
  name?: string;
  idparent?: string;
  idticketcategory?: string;
  fullname?: string;

  static parseToSelectOptions(
    data: Array<TicketCategoryDto>
  ): Array<SelectOptions> {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(
        new SelectOptions(
          CommonTools.processObjectField(element, ["id"]),
          CommonTools.processObjectField(element, ["fullname"])
        )
      );
    });
    return result;
  }
}

export class PostTicketCategoryDto implements Idto {
  idlanguage?: string;
  status?: number;
  ordercriteria?: number;
  name?: string;
  idparent?: string;
  idticketcategory?: string;
}
