import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  Divider,
} from "@mui/material";
import { LogoComp } from "components/general/LogoComp";

import { useAuth } from "hooks/useAuth";

import { MyNavBt } from "components/elements/button/MyNavBt";
import { useResource } from "hooks/useResource";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import { MyAvatar } from "../MyAvatar/MyAvatar";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { UserDrawer } from "./UserDrawer";

const drawerWidth = 300;
type MainDrawerProps = {
  open: boolean;
  setOpen: any;
  currentRoute: any;
};
const MainDrawer: React.FC<MainDrawerProps> = ({
  open,
  setOpen,
  currentRoute,
  ...props
}) => {
  const { user, usersettings } = useAuth();
  const { LL } = useResource();
  const [openUserDrawer, setOpenUserDrawer] = useState<boolean>(false);

  const handleClose = () => {
    setOpenUserDrawer(false);
    setOpen(false);
  };
  const handleOpenUserDrawer = () => {
    setOpenUserDrawer(true);
  };
  const processCloseButton = () => {
    return (
      <IconButton onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    );
  };

  const processLogo = () => {
    return <LogoComp />;
  };

  const processUserDrawer = () => {
    return (
      <UserDrawer
        open={openUserDrawer}
        setOpen={setOpenUserDrawer}
        setOpenMainDrawer={setOpen}
      />
    );
  };

  const processUserName = () => {
    if (!usersettings) return null;
    if (!usersettings.hasOwnProperty("name")) return null;
    if (!usersettings.hasOwnProperty("surname")) return null;
    if (!usersettings.name) return null;
    if (!usersettings.surname) return null;

    return (
      <Typography color="textPrimary">
        {usersettings.name} {usersettings.surname}
      </Typography>
    );
  };

  const processAvatar = () => {
    if (!usersettings) return null;
    if (!usersettings.hasOwnProperty("name")) return null;
    if (!usersettings.hasOwnProperty("surname")) return null;
    if (!usersettings.name) return null;
    if (!usersettings.surname) return null;

    return (
      <MyAvatar
        obj={usersettings}
        name={usersettings.name}
        surname={usersettings.surname}
        sx={{ mr: 1 }}
      />
    );
  };

  const processBottomMainSite = () => {
    const url = process.env.REACT_APP_WWW_SERVER
      ? process.env.REACT_APP_WWW_SERVER
      : "";
    return (
      <Box
        onClick={handleClose}
        onKeyDown={handleClose}
        role="presentation"
        sx={{ width: drawerWidth }}
      >
        <List>
          <ListItem>
            <ListItemButton
              additionalOnClick={handleClose}
              component={MyNavBt}
              href={url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ListItemText>
                <Stack direction="row" spacing={1}>
                  <ArrowBackIosNewIcon />
                  <Typography>{LL("BT_BackToMainSite")}</Typography>
                </Stack>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    );
  };
  const processAuthUser = () => {
    if (!user) return null;

    return (
      <Box
        onClick={handleOpenUserDrawer}
        role="presentation"
        sx={{ width: drawerWidth }}
      >
        <List>
          <ListItem>
            <ListItemButton>
              <ListItemAvatar>{processAvatar()}</ListItemAvatar>
              <ListItemText>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <Typography color="textPrimary">
                    {processUserName()}
                  </Typography>
                  <Typography color="textSecondary" className="subtextBage">
                    {user.email}
                  </Typography>
                </Stack>
              </ListItemText>
              <ListItemIcon sx={{ minWidth: "15px" }}>
                <ArrowForwardIosIcon className="iconRight" />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    );
  };

  const processUserActions = () => {
    const mainUrl = process.env.REACT_APP_WWW_SERVER
      ? process.env.REACT_APP_WWW_SERVER
      : "";
    const urlToSignUp = mainUrl ? mainUrl + "/signup" : "";
    if (!user) {
      return (
        <Box
          onClick={handleClose}
          onKeyDown={handleClose}
          role="presentation"
          sx={{ width: drawerWidth }}
        >
          <List>
            <ListItem>
              <ListItemButton
                additionalOnClick={handleClose}
                component={MyNavBt}
                href={urlToSignUp}
                className="btn btnBorder"
                target="_blank"
                rel="noopener noreferrer"
              >
                {LL("BT_SignUpTop")}
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton
                additionalOnClick={handleClose}
                component={MyNavBt}
                href={"/login"}
                className="btn btnContainer"
              >
                {LL("BT_SignInTop")}
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      );
    }
    return processAuthUser();
  };

  return (
    <Box>
      <Drawer open={open} onClose={handleClose} className="mobileMenu">
        <Stack direction="row" justifyContent="space-between">
          {processLogo()}
          {processCloseButton()}
        </Stack>
        <Divider />
        {processBottomMainSite()}
        {processUserActions()}
        <Divider />
        {processUserDrawer()}
      </Drawer>
    </Box>
  );
};

export { MainDrawer };
