import { ServerSelect } from "components/elements/Select/newSelect/ServerSelect";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { TicketCategoryDto } from "dto/support/ticketcategory.dto";
import React from "react";
import { TicketCategoryService } from "services/support/ticketcategory.service";
import { Status } from "tools/types/status";

type Props = {
  field?: string;
  value?: number | string;
  setObjectField: (field: string, value: any) => void;
  label?: string;
};
const service = new TicketCategoryService();
const SelectTicketCategory: React.FC<Props> = ({
  field = "idticketcategory",
  value,
  setObjectField,
  label = "",
}) => {
  const getList = (cb?: any, cbParams?: any, data?: RequestListDTO) => {
    if (!data) data = new RequestListDTO();
    data.filters = [
      RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
    ];
    service.getList(cb, cbParams, data);
  };

  return (
    <ServerSelect
      processOptions={TicketCategoryDto.parseToSelectOptions}
      getList={getList}
      field={field}
      setObjectField={setObjectField}
      value={value}
      label={label}
    />
  );
};

export { SelectTicketCategory };
