// import RequestListDTO from "dto/app/requestlist.dto";
// import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
// import ResultObjectDTO from "dto/app/resultobject.dto";
// // import {TicketCategoryDto} from "dto/support/ticketcategory.dto";
// import ResultListDTO from "dto/app/resultlist.dto";
// import { logger } from "tools/utils/logger";
// import IService from "interfaces/iservice.interface";
// import { TicketCategoryRepository } from "repositories/support/ticketcategory.repository";
// import { Status } from "tools//types/status";
// import GeneralService from "services/general.service";

// export default class TicketCategoryService
//   extends GeneralService
//   implements IService
// {
//   ticketCategoryRepository: TicketCategoryRepository =
//     new TicketCategoryRepository();
//   constructor() {
//     super();
//     this.handleGetList = this.handleGetList.bind(this);
//     this.handleGet = this.handleGet.bind(this);
//   }
//   handleUpdate(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;

//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   async update(
//     id: string,
//     cb?: any,
//     cbparameters?: any,
//     data?: any
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.ticketCategoryRepository.update(
//       id,
//       this.handleUpdate,
//       data,
//       cbparameters
//     );
//   }

//   handleDelete(result?: any, cbparameters?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters);
//     }
//   }

//   async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;

//     this.ticketCategoryRepository.delete(id, this.handleDelete, cbparameters);
//   }

//   handleGet(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;
//     this.populateObject(rez.obj, result.obj, [
//       { key: "status", type: "status" },
//     ]);
//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }

//     return rez;
//   }

//   async get(id: string, cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.ticketCategoryRepository.get(
//       id,
//       this.handleGet,
//       cbparameters
//     );
//     if (cb == undefined) {
//       return this.handleGet(t);
//     }
//   }

//   handleGetList(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultListDTO();

//     rez = result;
//     if (result.err) return;

//     this.parseResultPopulate(result, rez, [{ key: "status", type: "status" }]);

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }

//     return rez;
//   }

//   async getList(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.ticketCategoryRepository.getList(
//       this.handleGetList,
//       cbparameters,
//       data
//     );
//     if (cb == undefined) {
//       return this.handleGetList(t);
//     }
//   }

//   handleAdd(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   async add(cb?: any, cbparameters?: any, data?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;

//     this.ticketCategoryRepository.add(this.handleGetList, cbparameters, data);
//   }

//   getDefaultRequestList(onPage?: number): RequestListDTO {
//     onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
//     if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
//     var defaultRequestList = new RequestListDTO();
//     defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
//     defaultRequestList.onpage = onPage;
//     var defaultRequestSortCriteria = new RequestSortCriteriaDTO();
//     defaultRequestSortCriteria.asc = true;
//     defaultRequestSortCriteria.field = "name";
//     defaultRequestList.sortcriteria = [];
//     defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
//     return defaultRequestList;
//   }
// }


import GeneralService from "services/globalgeneral.service";
import IService from "interfaces/iservice.interface";

import {TicketCategoryRepository} from "repositories/support/ticketcategory.repository";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";

export class TicketCategoryService
  extends GeneralService<TicketCategoryRepository>
  implements IService
{
  constructor() {
    super(
      new TicketCategoryRepository(),
      [
        { key: 'status', type: 'status' },
      ],
      "name"
    );
  }
  

  getDefaultRequestListSelect(onPage?: number): RequestListDTO {
    onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
    if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
    var defaultRequestList = new RequestListDTO();
    defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
    defaultRequestList.onpage = onPage;
    var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
    defaultRequestSortCriteria.asc = true;
    defaultRequestSortCriteria.field = 'name';
    defaultRequestList.sortcriteria = []
    defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
    return defaultRequestList;
}
  
  getDefaultRequestListParent(
    obj: RequestListDTO,
    idParent: string
  ): RequestListDTO {
    if (
      !obj.sortcriteria ||
      !Array.isArray(obj.sortcriteria) ||
      !obj.sortcriteria.length
    ) {
      var sobj = new RequestSortCriteriaDTO();
      sobj.asc = true;
      sobj.field = "name";

      obj.sortcriteria = [sobj];
    }
    const filters = new Array<RequestFilterDTO>();
    const filter = new RequestFilterDTO();
    filter.field = "idparent";
    filter.values = [idParent];
    filters.push(filter);
    obj.filters = filters;

    return obj;
  }
  
}