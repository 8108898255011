import React, { useState, useEffect } from "react";

import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { TicketMessageDto } from 'dto/support/ticketmessage.dto'
import { MyButton } from "components/elements/button/MyButton";
import { CommonTools } from 'tools/utils/commontools';
import { ComponentCommonTools } from 'tools/utils/componentcommontools'
import { useResource } from "hooks/useResource";
import { RouteTools } from "tools/utils/routetools";
import { ListItemText, Link, ListItemIcon, ListItem } from "@mui/material";
import VideoFileIcon from '@mui/icons-material/VideoFile';
import { logger } from "tools/utils/logger";
import { VideoDto } from "dto/system/video.dto";


type VideoListItemPlayerDisplayProps = {
    obj: VideoDto;
    index: number;
}

const VideoListItemPlayerDisplay: React.FC<VideoListItemPlayerDisplayProps> = ({ obj,index, ...props }) => {

    const { LL } = useResource();


    const processItem = () => {
        if (!obj) return (<></>);
        if (!obj.hasOwnProperty('id')) return (<></>);
        if (!obj.id) return (<></>);
       
        return (
            <ListItem
                key={index}
            >
                {ComponentCommonTools.getFileBlockVideo(obj,300,200)}
            </ListItem>
        );
    }



    return processItem();
};

export { VideoListItemPlayerDisplay };
