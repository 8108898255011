import React from "react";
import { useResource } from "hooks/useResource";

import { ListItemText, ListItemIcon, ListItem } from "@mui/material";
import VideoFileIcon from "@mui/icons-material/VideoFile";

import { VideoDto } from "dto/system/video.dto";

type Props = {
  obj: VideoDto;
  index: number;
};

const VideoListItemTextualDisplay: React.FC<
  Props
> = ({ obj, index, ...props }) => {
  const { LL } = useResource();

  const processItem = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("id")) return null;
    if (!obj.id) return null;
    let name = "";
    if (obj.hasOwnProperty("name") && obj.name) name = obj.name;
    else {
      const indexLocal = index + 1;
      name = LL("video") + " " + indexLocal.toString();
    }
    return (
      <ListItem key={index}>
        <ListItemIcon>
          <VideoFileIcon />
        </ListItemIcon>
        <ListItemText primary={name} />
      </ListItem>
    );
  };

  return processItem();
};

export { VideoListItemTextualDisplay };
