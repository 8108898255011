import GeneralService from "services/globalgeneral.service";
import IService from "interfaces/iservice.interface";

import { AdvertisementRepository } from "repositories/static/advertisement.repository";


import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { PostAdvertisementDto } from "dto/static/advertisement.dto";


export class AdvertisementService
  extends GeneralService<AdvertisementRepository>
  implements IService
{
  constructor() {
    super(
      new AdvertisementRepository(),
      [
        { key: "status", type: "status" },
        { key: "section", type: "advertisementsection" },
        { key: "type", type: "advertisementtype" },
        { key: "startdate", type: "date" },
        { key: "enddate", type: "date" },
      ],
      "name"
    );
  }

  prepareObjToUpdate(obj: any): PostAdvertisementDto {
    const rez = new PostAdvertisementDto();

    rez.idlanguage = obj.idlanguage;

    rez.name = obj.name;
    rez.status = obj.status;
    rez.url = obj.url;
    rez.ordercriteria = obj.ordercriteria;
    rez.section = obj.section;
    rez.idadvertisement = obj.idadvertisement;
    rez.type = obj.type;
    rez.startdate = obj.startdate;
    rez.enddate = obj.enddate;
    rez.clabt = obj.clabt;
    rez.description = obj.description;
    return rez;
  }
  getDefaultRequestListSelect(onPage?: number): RequestListDTO {
    onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
    if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
    var defaultRequestList = new RequestListDTO();
    defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
    defaultRequestList.onpage = onPage;
    var defaultRequestSortCriteria = new RequestSortCriteriaDTO();
    defaultRequestSortCriteria.asc = true;
    defaultRequestSortCriteria.field = "name";
    defaultRequestList.sortcriteria = [];
    defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
    return defaultRequestList;
  }
}

// import RequestListDTO from "dto/app/requestlist.dto";
// import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
// import ResultObjectDTO from "dto/app/resultobject.dto";
// import { PostAdvertisementDto } from "dto/advertisement/advertisement.dto";
// import ResultListDTO from "dto/app/resultlist.dto";
// import { logger } from "tools/utils/logger";
// import IService from "interfaces/iservice.interface";
// import { AdvertisementRepository } from "repositories/static/advertisement.repository";
// import { Status } from "tools//types/status";
// import GeneralService from "services/general.service";
// import RequestFilterDTO from "dto/app/requestfilter.dto";

// export default class AdvertisementService
//   extends GeneralService
//   implements IService
// {
//   advertisementRepository: AdvertisementRepository =
//     new AdvertisementRepository();
//   constructor() {
//     super();
//     this.handleGetList = this.handleGetList.bind(this);
//     this.handleGet = this.handleGet.bind(this);
//   }
//   handleUpdate(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;

//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   async update(
//     id: string,
//     cb?: any,
//     cbparameters?: any,
//     data?: any
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     this.advertisementRepository.update(
//       id,
//       this.handleUpdate,
//       this.prepareObjToUpdate(data),
//       cbparameters
//     );
//   }

//   handleDelete(result?: any, cbparameters?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters);
//     }
//   }

//   async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;

//     this.advertisementRepository.delete(id, this.handleDelete, cbparameters);
//   }

//   handleGet(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     this.populateObject(rez.obj, result.obj, [
//       { key: "status", type: "status" },
//       { key: "section", type: "advertisementsection" },
//       { key: "type", type: "advertisementtype" },
//       { key: "startdate", type: "date" },
//       { key: "enddate", type: "date" },
//     ]);
//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }

//     return rez;
//   }

//   async get(
//     id: string,
//     cb?: any,
//     cbparameters?: any,
//     idLanguage?: string
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.advertisementRepository.get(
//       id,
//       this.handleGet,
//       cbparameters,
//       idLanguage
//     );
//     if (cb == undefined) {
//       return this.handleGet(t);
//     }
//   }

//   handleGetList(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultListDTO();

//     rez = result;
//     if (result.err) return;

//     this.parseResultPopulate(result, rez, [
//       { key: "status", type: "status" },
//       { key: "section", type: "advertisementsection" },
//       { key: "type", type: "advertisementtype" },
//       { key: "startdate", type: "date" },
//       { key: "enddate", type: "date" },
//     ]);

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }

//     return rez;
//   }

//   async getList(
//     cb?: any,
//     cbparameters?: any,
//     data?: RequestListDTO
//   ): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;
//     const t = await this.advertisementRepository.getList(
//       this.handleGetList,
//       cbparameters,
//       data
//     );
//     if (cb == undefined) {
//       return this.handleGetList(t);
//     }
//   }

//   handleAdd(result?: any, cbparameters?: any, data?: any): any {
//     let rez = new ResultObjectDTO();
//     rez = result;
//     if (result.err) return;

//     if (cbparameters && cbparameters._cb) {
//       cbparameters._cb(rez, cbparameters, data);
//     }
//   }

//   prepareObjToAdd(obj: any): PostAdvertisementDto {
//     const rez = new PostAdvertisementDto();

//     rez.idlanguage = obj.idlanguage;

//     rez.name = obj.name;
//     rez.status = obj.status;
//     rez.url = obj.url;

//     rez.ordercriteria = obj.ordercriteria;

//     rez.section = obj.section;
//     rez.type = obj.type;
//     rez.startdate = obj.startdate;
//     rez.enddate = obj.enddate;
//     rez.clabt = obj.clabt;
//     rez.description = obj.description;

//     return rez;
//   }

//   prepareObjToUpdate(obj: any): PostAdvertisementDto {
//     const rez = new PostAdvertisementDto();

//     rez.idlanguage = obj.idlanguage;

//     rez.name = obj.name;
//     rez.status = obj.status;
//     rez.url = obj.url;
//     rez.ordercriteria = obj.ordercriteria;
//     rez.section = obj.section;
//     rez.idadvertisement = obj.idadvertisement;
//     rez.type = obj.type;
//     rez.startdate = obj.startdate;
//     rez.enddate = obj.enddate;
//     rez.clabt = obj.clabt;
//     rez.description = obj.description;
//     return rez;
//   }

//   async add(cb?: any, cbparameters?: any, data?: any): Promise<any> {
//     cbparameters = cbparameters ? cbparameters : {};
//     cbparameters._cb = cb;

//     this.advertisementRepository.add(
//       this.handleGetList,
//       cbparameters,
//       this.prepareObjToAdd(data)
//     );
//   }

//   getDefaultRequestListSelect(onPage?: number): RequestListDTO {
//     onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
//     if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
//     var defaultRequestList = new RequestListDTO();
//     defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
//     defaultRequestList.onpage = onPage;
//     var defaultRequestSortCriteria = new RequestSortCriteriaDTO();
//     defaultRequestSortCriteria.asc = true;
//     defaultRequestSortCriteria.field = "name";
//     defaultRequestList.sortcriteria = [];
//     defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
//     return defaultRequestList;
//   }

//   getDefaultRequestList(obj: RequestListDTO): RequestListDTO {
//     if (
//       !obj.sortcriteria ||
//       !Array.isArray(obj.sortcriteria) ||
//       !obj.sortcriteria.length
//     ) {
//       var sobj = new RequestSortCriteriaDTO();
//       sobj.asc = true;
//       sobj.field = "name";

//       obj.sortcriteria = [];
//       obj.sortcriteria?.push(sobj);
//     }

//     return obj;
//   }
// }
