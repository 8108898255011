import { Box, Typography } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import MyTextField from "components/form/mytextfield";
import { RegisterTicketFormDto } from "dto/support/ticket.dto";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import React, { useEffect } from "react";
import RequiredValidator from "validators/required.validator";
import { SelectTicketCategory } from "./SelectTicketCategory";
import { StaticSelect } from "components/elements/Select/newSelect/StaticSelect";
import { Types } from "tools/types/types";

type Props = {
  defaultObj: RegisterTicketFormDto;
  onSubmit: (obj: RegisterTicketFormDto) => void;
  loading: boolean;
  showSelect?: boolean;
};

const FormContact: React.FC<Props> = ({
  defaultObj,
  onSubmit,
  loading,
  showSelect = false,
}) => {
  const { LL } = useResource();
  const [obj, disabled, setObjField, setObject] = useForm<RegisterTicketFormDto>(
    defaultObj,
    RequiredValidator.getValidators(["message", "idticketcategory",'type'])
  );

  useEffect(() => {
    if (!defaultObj) return;
    setObject(defaultObj);
  }, [defaultObj]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled || loading) return;
    onSubmit(obj);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        {showSelect && (
          <Box>
            <Typography variant="h6" mb={1}>
              {LL("Type_Ticket")}
            </Typography>
            <StaticSelect
              field="type"
              type="tickettype"
              value={obj.type}
              setObjField={setObjField}
              service={Types}
              label=""
            />
          </Box>
        )}
        <Box>
          <Typography variant="h6" mb={1}>
            {LL("TicketCategory")}
          </Typography>
          <SelectTicketCategory
            value={obj.idticketcategory}
            setObjectField={setObjField}
          />
        </Box>
        <Box>
          <Typography variant="h6" mb={1}>
            {LL("message")}
          </Typography>
          <MyTextField
            fullWidth
            id="message"
            name="message"
            minRows={4}
            variant="standard"
            color="primary"
            className="form-input"
            _label={LL("message")}
            size="small"
            value={obj.message}
            setObj={setObjField}
            multiline
            _vresults={undefined}
          />
        </Box>

        <Box textAlign="center" my={4}>
          <MyButton
            disabled={disabled || loading}
            color="primary"
            variant="contained"
            size="large"
            type="submit"
            className="btn"
          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormContact };
