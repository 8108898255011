import React from 'react';
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { BottomLogoBlock } from './BottomLogoBlock';
import { BottomLanguageBlock } from 'components/static/language/BottomLanguageBlock';
import { BottomMenuBlock } from 'components/static/menu/BottomMenuBlock';
import { InfoMenuBlock } from 'components/static/menu/InfoMenuBlock';
import { SocialBlock } from 'components/static/social/SocialBlock';
import { CopyRightBlock } from 'components/static/page/block/CopyRightBlock';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

const Footer: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {
  return (
    <div className='footer'>
      <div className='firstBlock row'>
      <Grid container className='container row'>
        <Grid item lg={8} xs={12} sx={{my:2}}>
          <BottomMenuBlock currentRoute={currentRoute} />
        </Grid>
        <Grid item lg={2} xs={12} sx={{my:2}}>
          <SocialBlock currentRoute={currentRoute} /> 
        </Grid>
        <Grid item lg={2} xs={12} sx={{my:2}}>
          <BottomLanguageBlock currentRoute={currentRoute} />
        </Grid>
      </Grid>           
      </div>
      
      <div className='secondBlock'>
        <div className='container row'>
            <BottomLogoBlock currentRoute={currentRoute} />
            <CopyRightBlock currentRoute={currentRoute} />
            <div className="infomenu"><InfoMenuBlock currentRoute={currentRoute} /></div>
        </div>
      </div>
    </div>
  );
};

export { Footer };
