import React, { createContext, useState, useEffect, useCallback } from "react";

import { useCookies } from "react-cookie";

import { Loading } from "components/elements/loading/Loading";

import { useAuth } from "hooks/useAuth";
import { LocalStorageApi } from "api/localstorage.api";
import { PermissionService } from "services/user/permission.service";
import { LabelService } from "services/static/label.service";
import { SiteConfigService } from "services/static/siteconfig.service";
import { SiteConfigFileService } from "services/static/siteconfigfile.service";
import { LanguageService } from "services/static/language.service";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { Status } from "tools//types/status";
import { CommonTools } from "tools/utils/commontools";
import { RouteTools } from "tools/utils/routetools";

import { PostSiteConfigDto, SiteConfigDto } from "dto/app/siteconfig.dto";
import GeneralRepository from "repositories/general.repository";

import { LabelTypes } from "tools/types/labeltypes";
import { Types } from "tools/types/types";
import GeneralService from "services/general.service";
import DateTools from "tools/utils/date.tools";
import { PostSiteConfigFileDto, SiteConfigFileDto } from "dto/app/siteconfigfile.dto";
import { FileTools } from "tools/utils/filetools";
import { Md5 } from "ts-md5";
import GlobalGeneralService from "services/globalgeneral.service";
import IProvider from "interfaces/provider.interface";

import ResultObjectDTO from "dto/app/resultobject.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { Config } from "tools/utils/config";
import { LabelDto } from "dto/static/label.dto";
import { PostLabelDto } from "dto/static/postlabel.dto";
import { LanguageDto } from "dto/static/language.dto";
import { PermissionDto } from "dto/user/permission.dto";

type Props = {
  LL: (identifier: string, labelType?: number) => string;
  CC: (identifier: string, value: string) => string;
  CF: (identifier: string) => string;
  LLL: (identifier: string, idLang?: string, labelType?: number) => string;
  currentRoute: any;
  setCurrentRoute: React.Dispatch<any>;
  langs: Array<LanguageDto>;
  _idlanguage: any;
  changeLanguage: (idl: any) => void;
  saveCache: (data: any, key: string) => void;
  getCache: (key: string) => any;
  isTeacher: () => boolean;
  searchFilterBlog: string;
  setSearchFilterBlog: React.Dispatch<any>;
  pageProgressLabel: number;
  pageProgressLabelTotal: number;
  setPageProgressLabel: (value: number) => void;
  setPageProgressLabelTotal: (value: number) => void;
  pageProgressConfig: number;
  pageProgressConfigTotal: number;
  setPageProgressConfig: (value: number) => void;
  setPageProgressConfigTotal: (value: number) => void;
  pageProgressConfigFile: number;
  pageProgressConfigFileTotal: number;
  setPageProgressConfigFile: (value: number) => void;
  setPageProgressConfigFileTotal: (value: number) => void;
};
export const ResourceContext = createContext<Props>({
  LL: (identifier: string, labelType?: number) => "",
  CC: (identifier: string, value?: string) => "",
  CF: (identifier: string) => "",
  LLL: (identifier: string, idLang?: string, labelType?: number) => "",
  currentRoute: {},
  setCurrentRoute: () => {},
  langs: [],
  _idlanguage: "",
  changeLanguage: (idl: any) => {},
  saveCache: (data: any, key: string) => {},
  getCache: (key: string) => {},
  isTeacher: () => false,
  searchFilterBlog: "",
  setSearchFilterBlog: () => {},
  pageProgressLabel: 0,
  pageProgressLabelTotal: 10,
  setPageProgressLabel: () => {},
  setPageProgressLabelTotal: () => {},
  pageProgressConfig: 0,
  pageProgressConfigTotal: 10,
  setPageProgressConfig: () => {},
  setPageProgressConfigTotal: () => {},
  pageProgressConfigFile: 0,
  pageProgressConfigFileTotal: 10,
  setPageProgressConfigFile: () => {},
  setPageProgressConfigFileTotal: () => {},
});
const permissionService = new PermissionService();
const labelService = new LabelService();
const langService = new LanguageService();
const siteConfigService = new SiteConfigService();
const siteConfigFileService = new SiteConfigFileService();
const cacheData: any = {};

export const ResourceProvider: React.FC<IProvider> = ({ children }) => {
  const [cookies, setCookie] = useCookies();

  // ------------------------------
  const [_labelType, setLabelType] = useState<number>(Config.LABEL_TYPE);
  // ------------------------------
  const [searchFilterBlog, setSearchFilterBlog] = useState<string>("");
  // ------------------------------
  var _labelsSaved: Array<any> = [];
  var _labelsTmp: Array<any> = [];
  var _labels: Array<LabelDto> = LocalStorageApi.getObject("_llabels") ?? [];

  const noData = !_labels || _labels.length === 0;
  const defaultPage = noData ? 0 : 10;
  const [pageProgressLabel, setPageProgressLabel] = useState(defaultPage);
  const [pageProgressLabelTotal, setPageProgressLabelTotal] = useState(10);

  var _siteConfigsSaved: Array<any> = [];
  var _siteConfigsTmp: Array<any> = [];
  var _siteConfigs: Array<any> =
    LocalStorageApi.getObject("_lsiteconfigs") ?? [];

    
  const noDataConfig = !_siteConfigs || _siteConfigs.length === 0;
  const defaultPageConfig = noDataConfig ? 0 : 10;
  const [pageProgressConfig, setPageProgressConfig] =
    useState(defaultPageConfig);
  const [pageProgressConfigTotal, setPageProgressConfigTotal] = useState(10);


  var _siteConfigFilesSaved: Array<any> = [];
  var _siteConfigFilesTmp: Array<any> = [];
  var _siteConfigFiles = LocalStorageApi.getObject("_lsiteconfigfiles") ?? [];

  
  const noDataConfigFile = !_siteConfigFiles || _siteConfigFiles.length === 0;
  const defaultPageConfigFile = noDataConfigFile ? 0 : 10;
  const [pageProgressConfigFile, setPageProgressConfigFile] =
    useState(defaultPageConfigFile);
  const [pageProgressConfigFileTotal, setPageProgressConfigFileTotal] = useState(10);


  var _localLabelsHash: string = LocalStorageApi.getValue("_llhash") ?? "";
  var _localSiteConfigsHash: string =
    LocalStorageApi.getValue("_lsiteconfighash") ?? "";
  var _localSiteConfigFilesHash: string =
    LocalStorageApi.getValue("_lsiteconfigfilehash") ?? "";
  // const [_localLabelsHash, setLocalLabelsHash] = useState("-1");
  const [_serverLabelsHash, setServerLabelsHash] = useState<any>("-1");
  const [_isLabelsLoaded, setIsLabelsLoaded] = useState(false);

  const [_serverSiteConfigsHash, setServerSiteConfigsHash] =
    useState<any>("-1");
  const [_isSiteConfigsLoaded, setIsSiteConfigsLoaded] = useState(false);

  const [_serverSiteConfigFilesHash, setServerSiteConfigFilesHash] =
    useState<any>("-1");
  const [_isSiteConfigFilesLoaded, setIsSiteConfigFilesLoaded] =
    useState(false);
  // const [_labels, setLabels] = useState([]);
  // var _labels = [];
  // ------------------------------

  const { user, roles, usersettings, updateUserSettings } = useAuth();

  const [currentRoute, setCurrentRoute] = useState<any>(false);

  // ------------------------------
  var _permissions: Array<PermissionDto> =
    LocalStorageApi.getObject("_lpermissions") ?? [];
  var _localPermissionsHash: string = LocalStorageApi.getValue("_lphash") ?? "";
  // const [_localPermissionsHash, setLocalPermissionsHash] = useState("-1");
  const [_serverPermissionsHash, setServerPermissionsHash] = useState("-1");
  const [_isPermissionsLoaded, setIsPermissionsLoaded] = useState(false);
  // const [_permissions, setPermissions] = useState(false);

  // ------------------------------

  // ------------------------------
  var _langs: Array<LanguageDto> = LocalStorageApi.getObject("_llangs") ?? [];
  var _localLangsHash = LocalStorageApi.getValue("_llanghash") ?? "";
  // const [_localLangsHash, setLocalLangsHash] = useState("-1");
  const [_serverLangsHash, setServerLangsHash] = useState("-1");
  const [_isLangsLoaded, setIsLangsLoaded] = useState(false);
  const [langs, setLangs] = useState<Array<LanguageDto>>([]);

  // ------------------------------
  const [_idlanguage, setIdLanguage] = useState<any>(false);
  const [languageCode, setLanguageCode] = useState("");
  // ------------------------------
  const [isLoading, setIsLoading] = useState(true);

  // ----------------------------------------

  const getCacheIdentifier = (key: string) => {
    let k = _idlanguage.toString();
    k += "_" + JSON.stringify(key);

    return Md5.hashStr(k);
  };

  const saveCache = (data: any, key: string) => {
    const identifier = getCacheIdentifier(key);

    cacheData[identifier] = data;
  };

  const getCache = (key: string) => {
    const identifier = getCacheIdentifier(key);

    if (cacheData[identifier] != undefined) return cacheData[identifier];

    return false;
  };
  // ----------------------------------------
  const returnLanguageCode = useCallback(() => {
    if (!languageCode) return "";
    else return languageCode;
  }, [languageCode]);

  useEffect(() => {
    GeneralRepository.setLanguageCode(returnLanguageCode);
  }, [returnLanguageCode]);
  // ----------------------------------------
  useEffect(() => {
    GeneralRepository.setIdLanguage(_idlanguage);
    RouteTools.setLL(LL);
    Status.setLL(LL);
    LabelTypes.setLL(LL);
    Types.setLL(LL);
    GeneralService.setLL(LL);
    RouteTools.setHasAccess(hasAccess);
    RouteTools.setCheckUser(checkUser);
    RouteTools.setIsTeacher(isTeacher);
    GlobalGeneralService.setLL(LL);
  }, [user, roles, usersettings, currentRoute, isLoading, _idlanguage]);

  // ----------------------------------------
  useEffect(() => {
    // loadLocalPermissionsHash();
    loadServerPermissionsHash();

    // loadLocalLabelsHash();
    loadServerSiteConfigsHash();
    loadServerSiteConfigFilesHash();
    loadServerLabelsHash();

    // loadLocalLangsHash();
    loadServerLangsHash();

    if (!_labelType) {
      const labelType = Config.LABEL_TYPE;
      setLabelType(labelType);
    }
  }, []);

  // ----------------------------------------
  useEffect(() => {
    checkPermissionsHash();
  }, [_localPermissionsHash, _serverPermissionsHash]);

  // const loadLocalPermissionsHash = () => {
  //   const _lphash = LocalStorageApi.getValue("_lphash");
  //   setLocalPermissionsHash(_lphash);
  // };

  const handleLoadServerPermissionsHash = (result: ResultObjectDTO) => {
    setServerPermissionsHash(
      CommonTools.processObjectField(result, ["obj", "hash"])
    );
  };

  const loadServerPermissionsHash = () => {
    permissionService.getServerPermissionsHash(handleLoadServerPermissionsHash);
  };

  const checkPermissionsHash = () => {
    if (_isPermissionsLoaded) return;

    if (_localPermissionsHash == "-1") return;
    if (_serverPermissionsHash == "-1") return;

    if (_localPermissionsHash == _serverPermissionsHash) {
      loadPermissionsLocal();
    } else {
      loadPermissionsServer();
    }
  };

  const loadPermissionsLocal = () => {
    // const _lpermissions = LocalStorageApi.getObject("_lpermissions");
    // setPermissions(_lpermissions);
  };

  const handleLoadPermissionsServer = (result: ResultListDTO) => {
    _permissions = result.objects ?? [];
    LocalStorageApi.saveValue("_lphash", _serverPermissionsHash);
    LocalStorageApi.saveObject("_lpermissions", result.objects);
    // setPermissions(result.objects);
  };

  const loadPermissionsServer = () => {
    permissionService.getList(handleLoadPermissionsServer);
  };

  useEffect(() => {
    if (_isPermissionsLoaded) return;
    if (!_permissions) setIsPermissionsLoaded(true);
  }, [_permissions]);

  // ----------------------------------------
  useEffect(() => {
    checkLabelsHash();
  }, [_localLabelsHash, _serverLabelsHash]);

  useEffect(() => {
    checkSiteConfigsHash();
  }, [_localSiteConfigsHash, _serverSiteConfigsHash]);

  useEffect(() => {
    checkSiteConfigFilesHash();
  }, [_localSiteConfigFilesHash, _serverSiteConfigFilesHash]);
  // const loadLocalLabelsHash = () => {
  //   const _llhash = LocalStorageApi.getValue("_llhash");
  //   setLocalLabelsHash(_llhash);
  // };

  const handleLoadServerLabelsHash = (result: ResultObjectDTO) => {
    setServerLabelsHash(
      CommonTools.processObjectField(result, ["obj", "hash"])
    );
  };

  const loadServerLabelsHash = () => {
    labelService.getServerLabelsHash(handleLoadServerLabelsHash);
  };

  const loadServerSiteConfigsHash = () => {
    siteConfigService.getServerSiteConfigHash(handleLoadServerSiteConfigsHash);
  };

  const loadServerSiteConfigFilesHash = () => {
    siteConfigFileService.getServerSiteConfigFilesHash(
      handleLoadServerSiteConfigFilesHash
    );
  };

  const handleLoadServerSiteConfigFilesHash = (result: ResultObjectDTO) => {
    setServerSiteConfigFilesHash(
      CommonTools.processObjectField(result, ["obj", "hash"])
    );
  };

  const handleLoadServerSiteConfigsHash = (result: ResultObjectDTO) => {
    setServerSiteConfigsHash(
      CommonTools.processObjectField(result, ["obj", "hash"])
    );
  };

  const checkLabelsHash = () => {
    if (_isLabelsLoaded) return;

    if (_localLabelsHash == "-1") return;
    if (_serverLabelsHash == "-1") return;

    if (_localLabelsHash == _serverLabelsHash) {
      loadLabelsLocal();
    } else {
      loadLabelsServer();
    }
  };

  const checkSiteConfigsHash = () => {
    if (_isSiteConfigsLoaded) return;

    if (_localSiteConfigsHash == "-1") return;
    if (_serverSiteConfigsHash == "-1") return;

    if (_localSiteConfigsHash == _serverSiteConfigsHash) {
      loadSiteConfigsLocal();
    } else {
      loadSiteConfigsServer();
    }
  };

  const checkSiteConfigFilesHash = () => {
    if (_isSiteConfigFilesLoaded) return;

    if (_localSiteConfigFilesHash == "-1") return;
    if (_serverSiteConfigFilesHash == "-1") return;

    if (_localSiteConfigFilesHash == _serverSiteConfigFilesHash) {
      loadSiteConfigFilesLocal();
    } else {
      loadSiteConfigFilesServer();
    }
  };
  const loadLabelsLocal = () => {
    // const _llabels = LocalStorageApi.getObject("_llabels");
    // _labels  = LocalStorageApi.getObject("_llabels");
    setIsLabelsLoaded(true);
    // setLabels(_llabels);
  };

  const loadSiteConfigsLocal = () => {
    setIsSiteConfigsLoaded(true);
  };

  const loadSiteConfigFilesLocal = () => {
    setIsSiteConfigFilesLoaded(true);
  };

  const handleLoadLabelsServer = (result: ResultListDTO) => {
    if (!result) return;
    if (!result.requestinfo) return;
    if (!result.requestinfo.page) return;
    if (result.totalpages === undefined || result.totalpages === null) return;
    _labelsTmp = [..._labelsTmp, ...(result.objects ?? [])];
    // // //logger("_labelsTmp_labelsTmp_labelsTmp_labelsTmp", _labelsTmp);
    // const _tl = Array.from(_labelsTmp).concat(result.objects);
    // _labelsTmp = _tl;
    setPageProgressLabelTotal(result.totalpages);
    setPageProgressLabel(result.requestinfo.page);
    if (result.requestinfo.page < result.totalpages) {
      const np = result.requestinfo.page + 1;
      loadLabelsServer(np);
    } else {
      _labels = _labelsTmp;
      LocalStorageApi.saveValue("_llhash", _serverLabelsHash);
      LocalStorageApi.saveObject("_llabels", _labelsTmp);
    }
    // _labels = result.objects;
    // LocalStorageApi.saveValue("_llhash", _serverLabelsHash);
    // LocalStorageApi.saveObject("_llabels", result.objects);
    // setIsLabelsLoaded(true);

    // setLabels(result.objects);
  };

  const loadLabelsServer = (p?: number) => {
    p = p ?? 1;
    var labelType = Config.LABEL_TYPE;

    const cbP = {};
    const data = new RequestListDTO();
    data.filters = [];
    data.page = p;
    data.onpage = 50;
    // data.onpage = 9999999;
    const f = new RequestFilterDTO();
    f.field = "type";
    f.values = [];
    f.values.push(labelType.toString());
    f.values.push(LabelTypes.LABEL_MESSAGE.toString());
    f.values.push(LabelTypes.LABEL_VALIDATION.toString());
    data.filters.push(f);

    labelService.getList(handleLoadLabelsServer, cbP, data);
  };

  const handleLoadSiteConfigsServer = (result: ResultListDTO) => {
    if (!result) return;
    if (!result.objects) return;
    if (!result.requestinfo) return;
    if (!result.requestinfo.page) return;
    if (!result.totalpages) return;
    _siteConfigsTmp = [..._siteConfigsTmp, ...result.objects];
    // LocalStorageApi.saveValue("_lsiteconfighash", _serverSiteConfigsHash);
    // LocalStorageApi.saveObject("_lsiteconfigs", result.objects);
    // setIsSiteConfigsLoaded(true);
    setPageProgressConfigTotal(result.totalpages);
    setPageProgressConfig(result.requestinfo.page);
    if (result.requestinfo.page < result.totalpages) {
      loadSiteConfigsServer(result.requestinfo.page + 1);
    } else {
      processSiteConfigs(_siteConfigsTmp);
    }
  };

  const processSiteConfigs = (result: Array<SiteConfigDto>) => {
    _siteConfigs = result ?? [];
    LocalStorageApi.saveValue("_lsiteconfighash", _serverSiteConfigsHash);
    LocalStorageApi.saveObject("_lsiteconfigs", result);
    setIsSiteConfigsLoaded(true);
  };

  const handleLoadSiteConfigFilesServer = (result: ResultListDTO) => {
    if (!result) return;
    if (!result.objects) return;
    if (!result.requestinfo) return;
    if (!result.requestinfo.page) return;
    if (!result.totalpages) return;
    _siteConfigFilesTmp = [..._siteConfigFilesTmp, ...result.objects];

    setPageProgressConfigFileTotal(result.totalpages);
    setPageProgressConfigFile(result.requestinfo.page);
    if (result.requestinfo.page < result.totalpages) {
      loadSiteConfigFilesServer(result.requestinfo.page + 1);
    } else {
      processSiteConfigFiles(_siteConfigFilesTmp);
    }
    // _siteConfigFiles = result.objects ?? [];
    // LocalStorageApi.saveValue(
    //   "_lsiteconfigfilehash",
    //   _serverSiteConfigFilesHash
    // );
    // LocalStorageApi.saveObject("_lsiteconfigfiles", result.objects);
    // setIsSiteConfigFilesLoaded(true);
  };

  const processSiteConfigFiles = (result: Array<SiteConfigFileDto>) => {
    _siteConfigFiles = result ?? [];
    LocalStorageApi.saveValue(
      "_lsiteconfigfilehash",
      _serverSiteConfigFilesHash
    );
    LocalStorageApi.saveObject("_lsiteconfigfiles", result);
    setIsSiteConfigFilesLoaded(true);
  };
  const loadSiteConfigsServer = (pageNumber?: number) => {
    pageNumber = pageNumber ?? 1;
    const cbP = {};
    const data = new RequestListDTO();
    data.filters = [];
    data.onpage = 50;
    data.page = pageNumber;

    siteConfigService.getList(handleLoadSiteConfigsServer, cbP, data);
  };

  const loadSiteConfigFilesServer = (pageNumber?: number) => {
    pageNumber = pageNumber ?? 1;
    const cbP = {};
    const data = new RequestListDTO();
    data.filters = [];
    data.onpage = 50;
    data.page = pageNumber;

    siteConfigFileService.getList(handleLoadSiteConfigFilesServer, cbP, data);
  };
  // ----------------------------------------
  useEffect(() => {
    checkLangsHash();
  }, [_localLangsHash, _serverLangsHash]);

  // const loadLocalLangsHash = () => {
  //   const _llanghash = LocalStorageApi.getValue("_llanghash");
  //   setLocalLangsHash(_llanghash);
  // };

  const handleLoadServerLangsHash = (result: ResultObjectDTO) => {
    setServerLangsHash(CommonTools.processObjectField(result, ["obj", "hash"]));
  };

  const loadServerLangsHash = () => {
    langService.getServerLangsHash(handleLoadServerLangsHash);
  };

  const checkLangsHash = () => {
    if (_isLangsLoaded) return;

    if (_localLangsHash == "-1") return;
    if (_serverLangsHash == "-1") return;

    if (_localLangsHash == _serverLangsHash) {
      loadLangsLocal();
    } else {
      loadLangsServer();
    }
  };

  const loadLangsLocal = () => {
    const _llangs = LocalStorageApi.getObject("_llangs");
    setIsLangsLoaded(true);
    setLangs(_llangs);
  };

  const handleLoadLangsServer = (result: ResultListDTO) => {
    _langs = result.objects ?? [];
    LocalStorageApi.saveValue("_llanghash", _serverLangsHash);
    LocalStorageApi.saveObject("_llangs", result.objects);
    setIsLangsLoaded(true);
    // //logger("handleLoadLangsServer", result.objects);
    setLangs(result.objects ?? []);
  };

  const loadLangsServer = () => {
    const cbP = {};
    const data = new RequestListDTO();
    data.filters = [];
    const f = new RequestFilterDTO();
    f.field = "status";
    f.values = [];
    f.values.push(Status.ACTIVE.toString());
    data.filters.push(f);

    langService.getList(handleLoadLangsServer, cbP, data);
  };

  // ----------------------------------------
  useEffect(() => {
    checkIsLoading();
  }, [
    _isPermissionsLoaded,
    _isLabelsLoaded,
    _isLangsLoaded,
    _labelType,
    _isSiteConfigsLoaded,
    _isSiteConfigFilesLoaded,
  ]);

  const checkIsLoading = () => {
    if (!isLoading) return;

    // if (!_isPermissionsLoaded) return;
    // if (!_isLabelsLoaded) return;
    if (!_isLangsLoaded) return;
    if (!_labelType) return;
    if (!_isSiteConfigsLoaded) return;
    if (!_isSiteConfigFilesLoaded) return;

    setIsLoading(false);
  };

  // ----------------------------------------

  // ----------------------------------------
  useEffect(() => {
    if (!_isLangsLoaded) return;
    processLanguage();
  }, [_isLangsLoaded]);

  const changeLanguage = (idl?: string) => {
    let exp = Config.COOKIE_EXPIRES;

    if (isNaN(exp)) exp = 0;

    setCookie("idlanguage", idl, {
      path: "/",
      expires: DateTools.getDate(exp),
    });
    setIdLanguage(idl);

    GeneralRepository.reloadFunction();
    updateUserSettings("idlanguage", idl);
  };

  const processLanguage = () => {
    if (
      usersettings != undefined &&
      usersettings != null &&
      usersettings.idlanguage != undefined &&
      usersettings.idlanguage != null &&
      usersettings.idlanguage
    ) {
      setIdLanguage(usersettings.idlanguage);
      return;
    }

    if (cookies.idlanguage) {
      setIdLanguage(cookies.idlanguage);
      return;
    }

    const lobj = getDefalutLanguage();

    if (lobj != null) {
      setIdLanguage(lobj.id);
    }
  };

  const getDefalutLanguage = () => {
    var lcode = Config.DEFAULT_LANGUAGE;
    lcode = lcode.toString().toLowerCase();

    var rez = null;
    for (var i in _langs) {
      if (_langs[i].cod2?.toString().toLowerCase() != lcode) continue;

      rez = _langs[i];
      break;
    }

    return rez;
  };

  // ----------------------------------------
  const processLanguageCode = useCallback(() => {
    if (!_langs) return;
    if (!_langs.length) return;
    if (!_idlanguage) return;
    const lang = _langs.find((x) => x.id === _idlanguage);
    if (!lang) return;
    if (!lang.hasOwnProperty("cod2")) return;
    if (!lang.cod2) return;
    setLanguageCode(lang.cod2);
  }, [_langs, _idlanguage, _langs.length]);

  useEffect(() => {
    processLanguageCode();
  }, [processLanguageCode]);
  // ----------------------------------------
  const SaveLabel = (
    identifier: string,
    preparedIdentifier: string,
    labelType?: number
  ) => {
    if (_labelsSaved.indexOf(identifier) !== -1) return;
    _labelsSaved.push(identifier);

    labelType = labelType != undefined ? labelType : _labelType;

    const obj = new PostLabelDto();
    obj.identifier = preparedIdentifier;
    obj.type = labelType;
    obj.status = Status.ACTIVE;
    obj.value = identifier;
    obj.idlanguage = _idlanguage;
    obj._nonupdate = "1";

    if (!Array.isArray(_labels)) _labels = [];
    _labels.push(obj);

    labelService.add(false, {}, obj);
  };

  const SaveLabelByLang = (
    identifier: string,
    preparedIdentifier: string,
    labelType?: number,
    idLang?: string
  ) => {
    if (_labelsSaved.indexOf(identifier) !== -1) return;
    _labelsSaved.push(identifier);

    labelType = labelType != undefined ? labelType : _labelType;
    idLang = idLang != undefined ? idLang : _idlanguage;

    const obj = new PostLabelDto();
    obj.identifier = preparedIdentifier;
    obj.type = labelType;
    obj.status = Status.ACTIVE;
    obj.value = identifier;
    obj.idlanguage = idLang;
    obj._nonupdate = "1";

    if (!Array.isArray(_labels)) _labels = [];
    _labels.push(obj);

    labelService.add(false, {}, obj);
  };

  const SaveSiteConfig = (identifier: string, value?: any) => {
    if (_siteConfigsSaved.indexOf(identifier) !== -1) return;
    _siteConfigsSaved.push(identifier);

    const obj = new PostSiteConfigDto();
    obj.identifier = CommonTools.prepareLabeldentifier(identifier);
    obj.value = value;
    obj._nonupdate = "1";

    if (!Array.isArray(_siteConfigs)) _siteConfigs = [];
    _siteConfigs.push(obj);

    siteConfigService.add(false, {}, obj);
  };

  const SaveSiteConfigFile = (identifier: string) => {
    if (_siteConfigFilesSaved.indexOf(identifier) !== -1) return;
    _siteConfigFilesSaved.push(identifier);

    const obj = new PostSiteConfigFileDto();
    obj.identifier = CommonTools.prepareLabeldentifier(identifier);
    obj.idfile = null;
    obj._nonupdate = "1";

    if (!Array.isArray(_siteConfigFiles)) _siteConfigFiles = [];
    _siteConfigFiles.push(obj);

    siteConfigFileService.add(false, {}, obj);
  };

  const LLL = (identifier: string, idLang?: string, labelType?: number) => {
    labelType = labelType != undefined ? labelType : _labelType;
    idLang = idLang != undefined ? idLang : _idlanguage;

    const preparedIdentifier = CommonTools.prepareLabeldentifier(identifier);

    if (!_idlanguage) return identifier;

    let value = "";
    let founded = false;

    for (var i in _labels) {
      if (!_labels[i]) continue;
      if (_labels[i].identifier != preparedIdentifier) continue;
      if (_labels[i].type?.toString() != labelType.toString()) continue;
      founded = true;

      for (var j in _labels[i].allvalues) {
        if (
          _labels[i].allvalues[j].idlanguage.toString() != idLang?.toString()
        ) {
          continue;
        }
        value = _labels[i].allvalues[j].value;

        break;
      }

      value = value != "" ? value : _labels[i].value ?? "";

      break;
    }

    if (founded) {
      value = value ?? identifier;
      return value;
    }

    SaveLabelByLang(identifier, preparedIdentifier, labelType, idLang);
    return identifier;
  };

  const LL = (identifier: string, labelType?: number) => {
    labelType = labelType ? labelType : _labelType;

    const preparedIdentifier = CommonTools.prepareLabeldentifier(identifier);

    if (!_idlanguage) return identifier;

    let value = "";
    let founded = false;

    for (var i in _labels) {
      if (!_labels[i]) continue;
      if (_labels[i].identifier != preparedIdentifier) continue;
      if (_labels[i].type?.toString() != labelType.toString()) continue;
      founded = true;

      for (var j in _labels[i].allvalues) {
        if (
          _labels[i].allvalues[j].idlanguage.toString() !=
          _idlanguage.toString()
        ) {
          continue;
        }
        value = _labels[i].allvalues[j].value;

        break;
      }

      value = value != "" ? value : _labels[i].value ?? "";

      break;
    }

    if (founded) {
      value = value ?? identifier;
      return value;
    }

    SaveLabel(identifier, preparedIdentifier, labelType);
    return identifier;
  };

  const CC = (identifier: string, value: string) => {
    value = value != undefined ? value : "10";

    let valueLocal = "";
    let founded = false;

    for (var i in _siteConfigs) {
      if (!_siteConfigs[i]) continue;
      if (_siteConfigs[i].identifier != identifier) continue;
      founded = true;

      for (var j in _siteConfigs[i].allvalues) {
        valueLocal = _siteConfigs[i].allvalues[j].value;

        break;
      }

      valueLocal = valueLocal != "" ? valueLocal : _siteConfigs[i].value;

      break;
    }

    if (founded) {
      valueLocal = valueLocal ?? valueLocal;
      return valueLocal;
    }

    SaveSiteConfig(identifier, value);
    return value;
  };

  const CF = (identifier: string) => {
    let valueLocal = null;
    let founded = false;
    // // //logger('CF Resurces', _siteConfigFiles)
    for (var i in _siteConfigFiles) {
      if (!_siteConfigFiles[i]) continue;
      if (_siteConfigFiles[i].identifier != identifier) continue;
      founded = true;

      for (var j in _siteConfigFiles[i].allvalues) {
        // // //logger('CF Resurces', j)
        valueLocal = _siteConfigFiles[i].allvalues[j].file;

        break;
      }

      valueLocal = valueLocal != null ? valueLocal : _siteConfigFiles[i].file;

      break;
    }

    if (founded) {
      valueLocal = valueLocal ?? valueLocal;
      // // //logger('CF Resurces', valueLocal)
      if (valueLocal) {
        if (valueLocal.hasOwnProperty("_id") && valueLocal._id) {
          const url = FileTools.getFileUrl(valueLocal._id);
          if (url) {
            return url;
          }
        }
      }
    }

    SaveSiteConfigFile(identifier);
    const defaultImage = process.env.REACT_APP_URL_NO_IMAGE
      ? process.env.REACT_APP_URL_NO_IMAGE
      : "/images/no-photo.png";
    return defaultImage;
  };

  // ----------------------------------------
  const hasAccess_GetPermission = (key: string): PermissionDto | boolean => {
    if (!_permissions) return false;
    if (!_permissions.length) return false;

    let rez: PermissionDto | boolean = false;
    for (var i in _permissions) {
      if (_permissions[i].name != key) continue;
      rez = _permissions[i];
      break;
    }

    return rez;
  };

  const hasAccess_CheckRoles = (permissionsObj: any) => {
    if (!permissionsObj) return true;
    if (!permissionsObj.acceptedroles) return true;
    if (permissionsObj.acceptedroles == undefined) return true;
    if (permissionsObj.acceptedroles == null) return true;
    if (!permissionsObj.acceptedroles.length) return true;

    if (!roles) return false;
    if (!roles.length) return false;

    var rez = false;
    for (var i in permissionsObj.acceptedroles) {
      if (roles.indexOf(permissionsObj.acceptedroles[i]) != -1) {
        rez = true;
        break;
      }
    }

    return rez;
  };

  const hasAccess = (key: string) => {
    // temporar:
    return true;

    if (!_permissions) return true;

    const permissionsObj = hasAccess_GetPermission(key);
    if (!permissionsObj) return true;

    return hasAccess_CheckRoles(permissionsObj);
  };

  const checkUser = () => {
    if (!user) return false;

    return true;
  };

  const isTeacher = () => {
    const id = process.env.REACT_APP_TEACHER_ROLE_ID ?? "";
    // //logger("TEACHER_ID", id);
    if (id === "") return false;
    // //logger("Roles", roles);
    if (!roles) return false;
    if (!Array.isArray(roles)) return false;
    if (roles.length === 0) return false;
    if (roles.includes(id)) return true;

    return false;
  };
  // ----------------------------------------
  const value = {
    LL,
    CC,
    LLL,
    CF,
    currentRoute,
    setCurrentRoute,
    langs,
    _idlanguage,
    changeLanguage,
    saveCache,
    getCache,
    isTeacher,
    searchFilterBlog,
    setSearchFilterBlog,
    pageProgressLabel,
    pageProgressLabelTotal,
    setPageProgressLabel,
    setPageProgressLabelTotal,
    pageProgressConfig,
    pageProgressConfigTotal,
    setPageProgressConfig,
    setPageProgressConfigTotal,
    pageProgressConfigFile,
    pageProgressConfigFileTotal,
    setPageProgressConfigFile,
    setPageProgressConfigFileTotal,
  };

  return isLoading ? (
    <Loading />
  ) : (
    <ResourceContext.Provider value={value}>
      {children}
    </ResourceContext.Provider>
  );
};
