import React, { useContext, useEffect } from "react";

import { Box,Divider, Grid } from "@mui/material";

import { SupportArticleContext } from "context/supportarticle.context";
import { SearchTopBlock } from "./SearchTopBlock";
import { CategorySearchCodeFilterList } from "./CategorySearchCodeFilterList";
import { SupportArticlesSearchList } from "./SupportArticlesSearchList";
import { MyBreadcrumbsCourse } from "components/elements/MyBreadcrumbs/MyBreadcrumbsCourse";
import BreadcrumbChildrenDto from "dto/app/breadcrumbchildren.dto";
import { useResource } from "hooks/useResource";


type Props = {
  currentRoute: any;
};

const SupportArticleSearchBlock: React.FC<Props> = ({ currentRoute }) => {
  const {
    actions: { resetFilters },
  } = useContext(SupportArticleContext);
  const { LL } = useResource();
  useEffect(() => {
    if (!currentRoute) return;
    if (currentRoute._mainobject !== "supportarticlesearch") {
      resetFilters();
    }
  }, [currentRoute]);

  const processBreadcrumbChildren = (): BreadcrumbChildrenDto[] => {
    const breadcrumbArray: BreadcrumbChildrenDto[] = [];
    let breadcrumb = new BreadcrumbChildrenDto();
    breadcrumb.url = "/";
    breadcrumb.label = LL("crumb_homepage");
    breadcrumbArray.push(breadcrumb);
    
    breadcrumb = new BreadcrumbChildrenDto();
    breadcrumb.url = "/";
    breadcrumb.label = LL("crumb_search_support_articles");
    breadcrumbArray.push(breadcrumb);
   

    return breadcrumbArray;
  };

  const processBreadcrumb = () => {
    const breadcrumbChildren = processBreadcrumbChildren();
    return (
      <Box className="breadcrumbs">
        <MyBreadcrumbsCourse
          currentRoute={currentRoute}
          childs={breadcrumbChildren}
        />
      </Box>
    );
  };

  return (
    <Box className="pageGeneral">
      <Box className="onlyBread" py={3}>
        <Box className="container">{processBreadcrumb()}</Box>
      </Box>
      <Box className="container" py={4}><SearchTopBlock /></Box>
      <Divider></Divider>
      
      <Box className="bg-center">
        <Box className="container">
        <Grid container>
          <Grid item sm={3} ><CategorySearchCodeFilterList currentRoute={currentRoute} /></Grid>
          <Grid item sm={9} className="content-support"><SupportArticlesSearchList currentRoute={currentRoute} /></Grid>
        </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export { SupportArticleSearchBlock };
