import React, { useEffect, useState } from "react";

import { useResource } from "hooks/useResource";

import { List, Skeleton } from "@mui/material";

import { VideoDto } from "dto/system/video.dto";
import ResultListDTO from "dto/app/resultlist.dto";

import RequestListDTO from "dto/app/requestlist.dto";

import { VideoService } from "services/system/video.service";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";
import { VideoListItem } from "./VideoListItem";
const videoService = new VideoService();
type VideoListProps = {
  idparent: string;
  parent: string;
  typeDisplay?: number;
};
const VideoList: React.FC<VideoListProps> = ({
  idparent,
  parent,
  typeDisplay = Types.TYPE_VIDEO_LIST_ITEM_DISPLAY_TEXTUAL,
  ...props
}) => {
  const { CC } = useResource();
  const [loading, setLoading] = useState<boolean>(false);
  const [objects, setObjects] = useState<Array<VideoDto>>([]);

  const getList = () => {
    if (!idparent) return;
    const reqList = new RequestListDTO();
    reqList.onpage = parseInt(CC("DefaultOnPageVideoList", "1000"));
    reqList.page = 1;
    reqList.filters = [];
    reqList.sortcriteria = [];

    const sortCriteria = new RequestSortCriteriaDTO();
    sortCriteria.field = "ordercriteria";
    sortCriteria.asc = true;
    reqList.sortcriteria.push(sortCriteria);

    let f: RequestFilterDTO;

    f = new RequestFilterDTO();
    f.field = "status";
    f.values = [];
    f.values.push(Status.ACTIVE.toString());
    reqList.filters.push(f);

    f = new RequestFilterDTO();
    f.field = "idparent";
    f.values = [idparent];
    reqList.filters.push(f);
    setLoading(true);

    videoService.getList(loadObjects, {}, reqList);
  };

  const loadObjects = (result: ResultListDTO) => {
    if (!result) return;
    if (!result.err) {
      const objects = result.objects ? result.objects : [];
      setObjects(objects);
    }
  };

  const checkLoading = () => {
    let load = false;
    if (!objects) load = true;
    setLoading(load);
  };

  useEffect(() => {
    checkLoading();
  }, [objects]);

  useEffect(() => {
    getList();
  }, [idparent]);

  const processItem = (item: VideoDto, i: number) => {
    if (!item) return <></>;

    return <VideoListItem index={i} obj={item} typeDisplay={typeDisplay} />;
  };
  const processLessonList = () => {
    if (loading) return <Skeleton variant="rectangular" height={50} />;
    if (!objects.length) return <></>;

    return (
      <List>
        {objects.map((item: VideoDto, i: number) => {
          return processItem(item, i);
        })}
      </List>
    );
  };
  return processLessonList();
};

export { VideoList };
