import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { Box, Typography } from "@mui/material";

import { VideoListCarousel } from "components/system/video/VideoListCarousel";
import { useResource } from "hooks/useResource";
import { SupportArticleTypeCategoryList } from "components/support/supportarticlecategory/SupportArticleTypeCategoryList";
import { HomePageArticleList } from "components/support/supportarticle/HomePageArticleList";
import { SupportArticleCategoryList } from "components/support/supportarticlecategory/SupportArticleCategoryList";

const HomeMainBlock: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  const { LL } = useResource();

  const processVideoCarouselBlock = () => {
    return (
      <Box>
        <Typography variant="h2" mt={4} mb={3}>
          {LL("Video_tutorial")}
        </Typography>
        <VideoListCarousel parent="supportarticle" />
      </Box>
    );
  };

  return (
    <Box className="container">
      <SupportArticleTypeCategoryList />
      <HomePageArticleList />
      {processVideoCarouselBlock()}
      <SupportArticleCategoryList />
    </Box>
  );
};

export { HomeMainBlock };
