import React, { useContext } from "react";
import { ListItemText, ListItemButton, ListItemIcon } from "@mui/material";
import { SupportArticleCategoryDto } from "dto/support/supportarticlecategory.dto";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { SupportArticleContext } from "context/supportarticle.context";
import { RouteTools } from "tools/utils/routetools";

type Props = {
  obj: SupportArticleCategoryDto;
  index: number;
  mainCode?: string;
};

const SupportArticleCategoryMenuItem: React.FC<Props> = ({ obj, index ,mainCode}) => {
  const {
    state: { selectedMainCategory },
    actions: { setSelectedMainCategory },
  } = useContext(SupportArticleContext);
  const handleSelect = () => {
    // //logger("SupportArticleCategoryMenuItem", s
    if (!obj) return;
    if (!obj.hasOwnProperty("id")) return;
    if (!obj.id) return;
    setSelectedMainCategory(obj.id);
    RouteTools.setHistory("/supportarticlecategory/" + obj.id, {});
  };

  const processSelected = () => {
    if (!obj) return false;
    if (!obj.hasOwnProperty("id")) return false;
    if (!obj.id) return false;
    if (!selectedMainCategory) return false;
    return obj.id === selectedMainCategory;
  };

  const processSelectedByMainCode = () => {
    if(!mainCode) return false;
    if (!obj) return false;
    if (!obj.hasOwnProperty("maincode")) return false;
    if (!obj.maincode) return false;
    return obj.maincode === mainCode;
  }

  const processImage = () => {
    if (!obj) return <></>;
    if (!obj.hasOwnProperty("gallery")) return <></>;
    if (!obj.gallery) return <></>;
    if (!obj.gallery.length) return <></>;
    return ComponentCommonTools.getFileBlockImage(
      obj.gallery[0]?.fileInfoObj,
      30,
      30
    );
  };
  const processItem = () => {
    if (!obj) return <></>;

    return (
      <ListItemButton
        selected={processSelected() || processSelectedByMainCode()}
        key={index}
        sx={{ p: 1 }}
        onClick={handleSelect}
      >
        <ListItemIcon className="w-icon">{processImage()}</ListItemIcon>
        <ListItemText
          primary={ComponentCommonTools.processObjectField(obj, "name")}
        />
      </ListItemButton>
    );
  };

  return processItem();
};

export { SupportArticleCategoryMenuItem };
