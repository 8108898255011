import React from "react";
import { MyNavBt } from "./MyNavBt";

import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hooks/useResource";
import { Stack, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Config } from "tools/utils/config";

const ButtonBackMainSite: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  const url = Config.WWW_SERVER
  const { LL } = useResource();

  return (
    <MyNavBt
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="back-link-element"
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <ArrowBackIosNewIcon sx={{ fontSize: 14 }} />
        <Typography>{LL("BT_BackToMainSite")}</Typography>
      </Stack>
    </MyNavBt>
  );
};

export { ButtonBackMainSite };
