import React from "react";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import {  Typography } from "@mui/material";
import { SupportArticleDto } from "dto/support/supportarticle.dto";
import { MyNavBt } from "components/elements/button/MyNavBt";

type Props = {
  currentRoute: any;
  obj: SupportArticleDto;
};

const SupportArticleItem: React.FC<Props> = ({ currentRoute, obj }) => {
  const processUrl = () => {
    if (!obj) return "";
    if (!obj.hasOwnProperty("fullurl")) return "";
    if (!obj.fullurl) return "";
    return obj.fullurl;
  };

  return (
    <MyNavBt className="link-classic" href={processUrl()}>
      <Typography sx={{my:1}}>
        {ComponentCommonTools.processObjectField(obj, "name")}
      </Typography>
    </MyNavBt>
  );
};

export { SupportArticleItem };
