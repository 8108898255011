import React, { useContext } from "react";
import { useResource } from "hooks/useResource";


import { Grid, List, ListItem, Typography } from "@mui/material";

import { SupportArticleContext } from "context/supportarticle.context";
import { SupportArticleCategoryDto } from "dto/support/supportarticlecategory.dto";
import { SupportArticleCategoryItem } from "./SupportArticleCategoryItem";
import { SkeletonSubject } from "components/elements/Skeleton/SkeletonSubjects";

const SupportArticleCategoryList: React.FC = () => {
  const { LL } = useResource();
  const {
    state: {
      articleSupportCategoryHomeObjects,
      isLoadingHomePageArticleCategory,
      
    },
  } = useContext(SupportArticleContext);

  

  const processItem = (obj: SupportArticleCategoryDto, index: number) => {
    if (!obj) return null

    return (
      <Grid item sm={6} md={4} key={index} className="category">
        <ListItem key={index} sx={{ p: 0, m: 0 }} className="item-category">
          
          <SupportArticleCategoryItem obj={obj} />
        </ListItem>
      </Grid>
    );
  };

  const processList = () => {
    if (!articleSupportCategoryHomeObjects.length) return null

    return (
      <React.Fragment>
        <Typography variant="h2" my={2}>
          {LL("HomeBlogCategoryList_TITLE")}
        </Typography>

        <List>
          <Grid container spacing={2}>
            {articleSupportCategoryHomeObjects.map(
              (item: SupportArticleCategoryDto, i: number) => {
                return processItem(item, i);
              }
            )}
          </Grid>
        </List>
      </React.Fragment>
    );
  };

  return isLoadingHomePageArticleCategory ? <SkeletonSubject /> : processList();
};

export { SupportArticleCategoryList };
