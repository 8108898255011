
import Idto from 'interfaces/idto.interface';

export default class ForgotPasswordDto implements Idto {
  email?: string;

  constructor(email?: string) {
    this.email = email || "";
  }
}
