import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hooks/useResource";

import { useAuth } from "hooks/useAuth";

import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';

import ListItemText from '@mui/material/ListItemText';

import {  Typography } from "@mui/material";
import { MyAvatar } from "components/elements/MyAvatar/MyAvatar";


const UserTopMenu: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  const { LL } = useResource();

  const { user, logout, usersettings } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleLogOut = () => {
    logout();
    setAnchorEl(null);
  }
 
  const handleClose = () => {
    setAnchorEl(null);
  };

  const processUserName = () => {

    if (!usersettings) return null
    if (!usersettings.hasOwnProperty('name')) return null
    if (!usersettings.hasOwnProperty('surname')) return null
    if (!usersettings.name) return null
    if (!usersettings.surname) return null

    return (
      <Typography color="textPrimary">{usersettings.name} {usersettings.surname}</Typography>
    )
  }

  const processAvatar = () => {
    if (!usersettings) return null
    if (!usersettings.hasOwnProperty('name')) return null
    if (!usersettings.hasOwnProperty('surname')) return null
    if (!usersettings.name) return null
    if (!usersettings.surname) return null

    return <MyAvatar 
    obj={usersettings} 
    name={usersettings.name} 
    surname={usersettings.surname} 
    sx={{ mr: 1 }} />
  }

  const processAvatarComponent = () => {
    if (!user) return null
    
    return processAvatar();

  }

  const processMenu = () => {
 
    return (
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleLogOut}>
          <ListItemText>{LL('Logout')} </ListItemText>
        </MenuItem>
      </Menu>
    )
  }
  return !user ? (
    null
  ) : (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {processAvatarComponent()}
        <Box sx={{ textTransform: 'none', textAlign: 'left' }}>
          <Typography color="textPrimary" component="div">{processUserName()}</Typography>
          <Typography color="textSecondary" className="subtextBage">{user.email}</Typography>
        </Box>
      </Button>
      {processMenu()}
    </>
  );
};

export { UserTopMenu };