import React from "react";

import { SupportArticleCategoryDto } from "dto/support/supportarticlecategory.dto";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { RouteTools } from "tools/utils/routetools";
import { Typography, Box } from "@mui/material";
type Props = {
  obj: SupportArticleCategoryDto;
};

const SupportArticleCategoryItem: React.FC<Props> = ({ obj }) => {
  

  const handleSelect = () => {
    if (!obj) return;
    if (!obj.hasOwnProperty("id")) return;
    if (!obj.id) return;
    const url = "/supportarticlecategory/" + obj.id;
    RouteTools.setHistory(url, {});
  };

  const processImage = () => {
    if (!obj) return <></>;
    if (!obj.hasOwnProperty("gallery")) return <></>;
    if (!obj.gallery) return <></>;
    if (!obj.gallery.length) return <></>;
    return ComponentCommonTools.getFileBlockImage(
      obj.gallery[0]?.fileInfoObj,
      63,
      63
    );
  };
  const processItem = () => {
    if (!obj) return <></>;

    return (
      <Box onClick={handleSelect}>
        <Box>{processImage()}</Box>
        <Typography variant="h4" sx={{ my: 2 }}>
          {ComponentCommonTools.processObjectField(obj, "name")}
        </Typography>
        <Typography>
          {ComponentCommonTools.processObjectField(obj, "shortdescription")}
        </Typography>
      </Box>
    );
  };

  return processItem();
};

export { SupportArticleCategoryItem };
