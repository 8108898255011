import React, { useState, useEffect } from "react";
import { BlogDto } from "dto/static/blog.dto";
import RequestListDTO from "dto/app/requestlist.dto";

import { useResource } from "hooks/useResource";

import {BlogService} from "services/static/blog.service";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { Status } from "tools/types/status";

import ResultListDTO from "dto/app/resultlist.dto";
import { Box,  List, ListItem, Skeleton, Typography } from "@mui/material";
import { SimilarBlogItem } from "./SimilarBlogItem";

const blogService = new BlogService();

type SimilarBlogListProps = {
    currentRoute: any;
    idCategory: string;
    idCurrentBlog: string;
}
const SimilarBlogList: React.FC<SimilarBlogListProps> = ({
    currentRoute,
    idCategory,
    idCurrentBlog,
    ...props }) => {

    const { LL, CC } = useResource();


    const [isLoading, setIsLoading] = useState(true);
    const [objects, setObjects] = useState<Array<BlogDto>>([]);

    const [page, setPage] = useState<number>(1);
    const [total, setTotal] = useState<number>(-1);
    const [totalPage, setTotalPage] = useState<number>(-1);
    const [responseParams, setResponseParams] = useState<any>({});
    const [onPage, setOnPage] = useState<number>(parseInt(CC('BlogListOnPageOnStatic', '10')));
    const [reqList, setReqList] = useState<RequestListDTO>(new RequestListDTO());



    const getList = () => {


        setIsLoading(true);

        reqList.onpage = onPage;
        reqList.page = page;
        reqList.filters = [];

        let f: RequestFilterDTO;

        f = new RequestFilterDTO();
        f.field = "status";
        f.values = [];
        f.values.push(Status.ACTIVE.toString());
        reqList.filters.push(f);

        f = new RequestFilterDTO();
        f.field = "idblogcategory";
        f.values = [idCategory];
        reqList.filters.push(f);

        blogService.getList(loadObjects, {}, reqList);

        // setIsLoading(false);
    };


    const loadObjects = (result: ResultListDTO) => {
        if (!result) return;
        if (!result.err) {
            const objects = result.objects ? result.objects : [];
            const total = result.total !== undefined ? result.total : -1;
            const totalPage = result.totalpages !== undefined ? result.totalpages : -1;
            const reqInfo = result.requestinfo ? result.requestinfo : {};
            setObjects(objects);
            setTotal(total);
            setTotalPage(totalPage);
            setResponseParams(reqInfo);
        }
    }

    const checkLoading = () => {
        let load = false;

        if (total === -1) return true;
        if (totalPage === -1) return true;
        if (!objects) load = true;
        // if (objects.length === 0) load = true;
        if (Object.keys(responseParams).length === 0) return true;

        setIsLoading(load);
    }

    useEffect(() => {
        getList();
    }, [
        idCurrentBlog
    ]);

    useEffect(() => {
        checkLoading();
    }, [objects]);



    const processItem = (obj: BlogDto, index: number) => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('id')) return <></>;
        if (!obj.id) return <></>;
        if (obj.id === idCurrentBlog) return <></>;
        return (<ListItem key={index}>
            <SimilarBlogItem obj={obj} />
        </ListItem>);

    };

    const processList = () => {
        if (isLoading) return <Skeleton variant="rectangular" />
        if (!objects.length) return <></>;

        return (
            <List>
                {objects.map((item: BlogDto, i: number) => {
                    return processItem(item, i);
                })}
            </List>
        );
    };



    const processComponent = () => {
        if (!(objects.length - 1)) return <></>;
        return (
            <Box >
                <Typography variant="h3" >{LL('SimilarBlogs')}</Typography>
                {processList()}
            </Box>

        );
    }

    return processComponent();
};

export { SimilarBlogList };
