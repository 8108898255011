import SelectOptions from "dto/app/selectoptions.dto";
import Idto from "interfaces/idto.interface";
import { CommonTools } from "tools/utils/commontools";

export class LanguageDto implements Idto {

    id?: string;
    name?: string;
    cod2?: string;
    cod3?: string;
    status?: number;
    ordercriteria?: number;
    hmtlcode?: string;
    status_name?: any;

    static parseToSelectOptions(data: Array<LanguageDto>): Array<SelectOptions> {
        if (!data) return [];
        if (!Array.isArray(data)) return [];
        if (!data.length) return [];
        let result: Array<SelectOptions> = [];
        data.forEach((element) => {
          result.push(
            new SelectOptions(
              CommonTools.processObjectField(element, ["id"]),
              CommonTools.processObjectField(element, ["name"])
            )
          );
        });
        return result;
      }
}
