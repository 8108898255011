import React,{useEffect} from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { HomeSearchBlock } from "components/static/page/homepage/HomeSearchBlock";

import { HomeMainBlock } from "components/static/page/homepage/HomeMainBlock";
import {PageService} from "services/static/page.service";
const service = new PageService();
const HomePage: React.FC<PageComponentProps> = ({ currentRoute, ...props }) => {


  const getHomeSEO = () => {
    service.getHomeSEO();
  };

  useEffect(() => {
    getHomeSEO()
  }, []);

  return <React.Fragment>
    <HomeSearchBlock currentRoute={currentRoute} />
    <HomeMainBlock currentRoute={currentRoute} />
  </React.Fragment>;
};

export { HomePage };
