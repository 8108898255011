import React, { useEffect, useState } from "react";
import MenuItemProps from "interfaces/menuitemprops.interface";
import { MyNavBt } from "components/elements/button/MyNavBt";
import { InfoMenuLevel } from "./InfoMenuLevel";
import Box from "@mui/material/Box";


const InfoMenuItem: React.FC<MenuItemProps> = ({
  currentRoute,
  level,
  obj,
  hasChildren,
  ...props
}) => {
  const [nextLevel, setNextLevel] = useState<number>(1);
  const [maxLevel, setMaxLevel] = useState<number>(1);

  useEffect(() => {
    let ml: number = parseInt(process.env.REACT_APP_MAXLEVEL_INFOMENU ?? "1");
    ml = isNaN(ml) || ml == undefined ? 1 : ml;
    setMaxLevel(ml);
  }, []);

  useEffect(() => {
    setNextLevel(level + 1);
  }, [level]);

  const processBT = () => {
    if (!obj) return;

    if (!obj.fullurl) {
      return <>{obj.name}</>;
    }

    return <MyNavBt href={obj.fullurl}>{obj.name}</MyNavBt>;
  };

  const processDetail = () => {
    
    if (!obj) return <></>;

    return <Box>{processBT()}</Box>;
  };

  const processChilds = () => {
    if(!hasChildren) return <></>;
    if (nextLevel > maxLevel) return <></>;
    if (!obj) return <></>;

    return (
      <Box>
        <InfoMenuLevel
          currentRoute={currentRoute}
          level={nextLevel}
          idparent={obj.id ?? ""}
        />
      </Box>
    );
  };

  return (
    <Box>
      {processDetail()}
      {processChilds()}
    </Box>
  );
};

export { InfoMenuItem };
