import React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Unstable_Grid2";

import { useResponsive } from "hooks/useResponsive";

type Props = {
  lines?: number;
  width?: number;
};

const SkeletonBanner: React.FC = () => {
  const { matchesSM } = useResponsive();

  let gridItemsCount;
  if (matchesSM) {
    gridItemsCount = 4;
  } else {
    gridItemsCount = 12;
  }
  const skeletonHeight = matchesSM ? "790px" : "870px";
  // const secondRow = matchesSM ? '780px' : '900px';

  const gridItem = (
    <Grid xs={gridItemsCount}>
      <Grid container spacing={2}>
        <Grid xs={4}>
          <Skeleton variant="circular" width={50} height={50} />
        </Grid>
        <Grid xs={8} sx={{ width: "60%" }}>
          <Skeleton variant="text" sx={{ fontSize: "1rem", width: "100%" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Box sx={{ width: "100vw", height: skeletonHeight }} className="">
      <Skeleton sx={{ width: "100%", height: "640px" }} variant="rectangular" />
      <Box
        sx={{
          width: "90%",
          // height: "75px",
          padding: "34px 15px 34px 15px",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          margin: "auto",
        }}
      >
        <Grid
          container
          xs={12}
          sx={{ maxWidth: "1522px", margin: "auto" }}
          spacing={2}
        >
          {gridItem}
          {gridItem}
          {gridItem}
        </Grid>
      </Box>
    </Box>
  );
};

const SkeletonBanner2: React.FC<Props> = ({ width }) => {
  return (
    <Box sx={{ width: "100%", height: "auto" }} className="">
      <Skeleton sx={{ width: "100%", height: width }} variant="rectangular" />
    </Box>
  );
};

const SkeletonCourseBlock: React.FC = () => {
  const { matchesSM, matchesLG } = useResponsive();

  let gridCols = 10;
  let gridItemsCount;
  if (matchesLG) {
    gridCols = 3;
    gridItemsCount = 4;
  } else if (matchesSM) {
    gridCols = 4;
    gridItemsCount = 3;
  } else {
    gridCols = 12;
    gridItemsCount = 1;
  }

  const gridItemCourse = (
    <Grid xs={gridCols}>
      <Skeleton sx={{ width: "100%", height: "180px" }} variant="rectangular" />
      <Skeleton variant="text" sx={{ fontSize: "1.25rem" }} />
      <Skeleton variant="text" sx={{ fontSize: "1rem", marginTop: "15px" }} />
      <Skeleton
        variant="text"
        sx={{ fontSize: "1rem", marginBottom: "15px", width: "60%" }}
      />
      <Grid xs={12} container spacing={2}>
        <Grid xs={6} sx={{ width: "50%" }}>
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
        </Grid>
        <Grid xs={6} sx={{ width: "50%" }}>
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
        </Grid>
      </Grid>
      <Skeleton variant="text" sx={{ fontSize: "1rem", width: "75px" }} />
      <Skeleton variant="text" sx={{ fontSize: "1rem", width: "50%" }} />
      <Skeleton variant="text" sx={{ fontSize: "1rem", width: "50%" }} />
      <Skeleton variant="text" sx={{ fontSize: "1rem", width: "50%" }} />
      <Grid container spacing={2} sx={{ marginTop: "15px" }}>
        <Grid xs={6}>
          <Skeleton
            sx={{
              height: "50px",
              width: { xs: "120px", sm: "60px", md: "110px" },
            }}
            variant="rounded"
          />
        </Grid>
        <Grid xs={6} sx={{ width: "50%" }}>
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
        </Grid>
      </Grid>
    </Grid>
  );

  // const gridItems = Array.from(
  //   { length: gridItemsCount },
  //   () => gridItemCourse
  // );

  return (
    <Box
      sx={{
        width: "87%",
        height: "580px",
        marginX: "auto",
        padding: "33px 0px 33px 0px",
      }}
      className=""
    >
      <Grid
        container
        xs={12}
        sx={{ maxWidth: "1522px", margin: "auto" }}
        spacing={2}
      >
        {Array(gridItemsCount)
          .fill(null)
          .map((_, index) => (
            <React.Fragment key={index}>{gridItemCourse}</React.Fragment>
          ))}
      </Grid>
    </Box>
  );
};

const SkeletonCarousel: React.FC = () => {
  const { matchesSM, matchesLG } = useResponsive();

  let gridCols = 10;
  let gridItemsCount;
  if (matchesLG) {
    gridCols = 3;
    gridItemsCount = 4;
  } else if (matchesSM) {
    gridCols = 4;
    gridItemsCount = 3;
  } else {
    gridCols = 12;
    gridItemsCount = 1;
  }

  const gridItemCourse = (
    <Grid xs={gridCols}>
      <Skeleton sx={{ width: "100%", height: "180px" }} variant="rectangular" />
    </Grid>
  );

  // const gridItems = Array.from(
  //   { length: gridItemsCount },
  //   () => gridItemCourse
  // );

  return (
    <Box
      sx={{
        width: "87%",
        height: "autos",
        marginX: "auto",
        padding: "33px 0px 33px 0px",
      }}
    >
      <Grid
        container
        xs={12}
        sx={{ maxWidth: "1522px", margin: "auto" }}
        spacing={2}
      >
        {Array(gridItemsCount)
          .fill(null)
          .map((_, index) => (
            <React.Fragment key={index}>{gridItemCourse}</React.Fragment>
          ))}
      </Grid>
    </Box>
  );
};

export {
  SkeletonBanner,
  SkeletonCourseBlock,
  SkeletonBanner2,
  SkeletonCarousel,
};
