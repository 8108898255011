import Idto from "interfaces/idto.interface";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";

export class TicketDto implements Idto {
  id?: string;
  status?: number;
  type?: number;
  idoperator?: string;
  idstudent?: string;
  idteacher?: string;
  idticketcategory?: string;
}

export class PostTicketDto implements Idto {
  status?: number;
  type?: number;
  idoperator?: string;
  idstudent?: string;
  idteacher?: string;
  idticketcategory?: string;
}

export class RegisterTicketDto implements Idto {
  type?: number;
  idstudent?: string;
  idteacher?: string;
  idticketcategory?: string;
  message?: string;
}

export class RegisterTicketFormDto implements Idto {
  type?: number;
  idstudent?: string;
  idteacher?: string;
  idticketcategory?: string;
  message?: string;

  constructor(type?: number, idticketcategory?: string, message?: string) {
    this.type = type || Types.TYPE_TICKET_STUDENT;
    this.idticketcategory = idticketcategory || "";
    this.message = message || "";
  }
}
