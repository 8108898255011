import React, { useContext, useEffect } from "react";
import { Box, Skeleton } from "@mui/material";
import { SupportArticleContext } from "context/supportarticle.context";
import { NoResult } from "components/general/NoResult";
import { SupportArticleDto } from "dto/support/supportarticle.dto";
import { SupportArticlesSearchItem } from "./SupportArticlesSearchItem";
import { LoadMoreButton } from "components/elements/MyButton/LoadMoreButton";
import { useResource } from "hooks/useResource";
import { useCookies } from "react-cookie";
import { RouteTools } from "tools/utils/routetools";
import { SkeletonSearchResult } from "components/elements/Skeleton/SkeletonSearch";

type Props = {
  currentRoute: any;
};

const SupportArticlesSearchList: React.FC<Props> = ({ currentRoute }) => {
  const {
    actions: {
      getArticlesListSearch,
      setOnPage,
    },
    state: {
      articleSearchObjects,
      loadingSearch,
      codeFilterCriteria,
      total,
      onPage,
      searchArticle,
    },
  } = useContext(SupportArticleContext);
  const { CC } = useResource();
  
  const processItem = (obj: SupportArticleDto) => {
    if (!obj) return <></>;
    return <SupportArticlesSearchItem item={obj} />;
  };


  useEffect(() => {
    getArticlesListSearch();
  }, [searchArticle, codeFilterCriteria, onPage]);

  const processLoadMoreObjects = () => {
    if (!articleSearchObjects || !articleSearchObjects.length) return <></>;
    return (
      <LoadMoreButton
        objects={articleSearchObjects}
        total={total}
        onPage={onPage}
        setOnPage={setOnPage}
        step={parseInt(CC("ON_PAGE_SUPPORT_ARTICLE_SEARCH_PAGE", "10"))}
        className="loadMorebtn"
      />
    );
  };
  const processList = () => {
    if (!articleSearchObjects.length) return <NoResult identifier="articles" />;
    return articleSearchObjects.map((obj) => processItem(obj));
  };

  return !loadingSearch ? (
    <Box>
      <Box>{processList()}</Box>
      <Box>{processLoadMoreObjects()}</Box>
    </Box>
  ) : (
    <SkeletonSearchResult lines={7} />
  );
};

export { SupportArticlesSearchList };
