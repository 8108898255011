
import Idto from 'interfaces/idto.interface';

export default class UserDto implements Idto {
  id?: string;
  status?: number;
  registerdate?: number;
  email?: string;
  statusemail?: number;
  password?: string;
  statuspassword?: number;
  hash?: string;
  registerdate_name?: any;
  statusemail_name?: any;
  statuspassword_name?: any;
  status_name?: any;
  idusersettings?: string;
  userroles?: any;
  fullurl?: string;
}
