import React, { useEffect, useState } from "react";
import { useResource } from "hooks/useResource";

import { AdvertisementService } from "services/static/advertisement.service";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { AdvertisementDto } from "dto/static/advertisement.dto";

import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/types/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";

import { Types } from "tools/types/types";
import { Box } from "@mui/material";
import { AdvertisementSearchItem } from "./AdvertisementSearchItem";
import { SkeletonBanner2 } from "components/elements/Skeleton/Skeleton";
const advertisementService = new AdvertisementService();

const AdvertisementSearch: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  const { saveCache, getCache } = useResource();

  const [isLoading, setIsLoading] = useState(true);
  const [objects, setObjects] = useState<Array<AdvertisementDto>>([]);

  const getList = async () => {
    const reqList = new RequestListDTO();
    reqList.onpage = 1;
    reqList.page = 1;
    reqList.filters = [];

    let f: RequestFilterDTO;

    f = new RequestFilterDTO();
    f.field = "status";
    f.values = [];
    f.values.push(Status.ACTIVE.toString());
    reqList.filters.push(f);

    f = new RequestFilterDTO();
    f.field = "type";
    f.values = [];
    f.values.push(Types.ADVERTISEMENT_TYPE_SEARCH_SUPPORT.toString());
    reqList.filters.push(f);

    f = new RequestFilterDTO();
    f.field = "section";
    f.values = [];
    f.values.push(Types.SECTION_TOP_ADVERTISEMENT.toString());
    reqList.filters.push(f);

    const cd = getCache(JSON.stringify(reqList));

    if (cd !== false) {
      setObjects(cd);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    const data = await advertisementService.getList(undefined, {}, reqList);

    const rez = !data || !data.objects ? [] : data.objects;

    setObjects(rez);
    setIsLoading(false);

    saveCache(rez, JSON.stringify(reqList));
  };

  useEffect(() => {
    getList();
  }, []);

  const processAdvertisementItem = (obj: AdvertisementDto, index: number) => {
    if (!obj) return null;

    return (
      <Box key={index}>
        <AdvertisementSearchItem obj={obj} />
      </Box>
    );
  };

  const processAdvertisementList = () => {
    if (!objects.length) return null;

    return (
      <>
        {objects.map((item: AdvertisementDto, i: number) => {
          return processAdvertisementItem(item, i);
        })}
      </>
    );
  };

  return isLoading ? (
    <SkeletonBanner2 width={527} />
  ) : (
    processAdvertisementList()
  );
};

export { AdvertisementSearch };
