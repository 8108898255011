import { Box, Breadcrumbs, Stack, Typography } from "@mui/material";
import { MyNavBt } from "components/elements/button/MyNavBt";
import { BlogBreadcrumbSearch } from "components/static/blog/BlogBreadcrumbSearch";
import { ArticleBreadcrumbSearch } from "components/support/supportarticlesearch/ArticleBreadcrumbSearch";
import BreadcrumbChildrenDto from "dto/app/breadcrumbchildren.dto";
import { useResource } from "hooks/useResource";
import React from "react";

type MyBreadcrumbsCourseProps = {
  [x: string]: any;
  childs: BreadcrumbChildrenDto[];
  currentRoute: any;
  setIdBlogCategory?: any;
  setIdTypeBlogCategory?: any;
};

const MyBreadcrumbsCourse: React.FC<MyBreadcrumbsCourseProps> = ({
  childs,
  currentRoute,
  setIdBlogCategory,
  setIdTypeBlogCategory,
  ...props
}) => {
  const processItem = (item: BreadcrumbChildrenDto, i: any) => {
    var key = "breadcrumbs_" + i;
    const state = item.hState ? item.hState : {};
    return item.url ? (
      <MyNavBt
        href={item.url}
        underline="hover"
        key={key}
        color="text.secondary"
        _hstate={state}
      >
        {item.label}
      </MyNavBt>
    ) : (
      <Typography key={key}>{item.label}</Typography>
    );
  };

  return (
    <Stack
      direction={{md: 'row', sm: 'column'}}
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
      useFlexGap flexWrap="wrap"
    >
      <Box>
        <Breadcrumbs
          separator="›"
          className="breadcrumbs"
          aria-label="breadcrumb"
          color="text.disabled"
        >
          {childs.map((item, i) => {
            return processItem(item, i);
          })}
        </Breadcrumbs>
      </Box>
      <Box className="w-diferent">
        <ArticleBreadcrumbSearch currentRoute={currentRoute} />
      </Box>
    </Stack>
  );
};

export { MyBreadcrumbsCourse };
