import React from "react";


type Props = {
  content?: string | undefined | null;
}

const HtmlTag: React.FC<Props> = ({
  content,
  ...props
}) => {
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: content != undefined && content != null ? content : "",
      }}
    />
  );
};

export { HtmlTag };
