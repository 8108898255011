// import RequestListDTO from "dto/app/requestlist.dto";
// import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
// import ResultObjectDTO from "dto/app/resultobject.dto";
// import { PostBlogDto } from "dto/blog/blog.dto";
// import ResultListDTO from "dto/app/resultlist.dto";
// import { logger } from "tools/utils/logger";
// import IService from "interfaces/iservice.interface";
// import {BlogRepository} from "repositories/static/blog.repository";
// import { Status } from "tools//types/status";

// import GeneralService from "services/general.service";
// export default class BlogService extends GeneralService implements IService {
//     blogRepository: BlogRepository = new BlogRepository();
//     constructor() {
//         super();
//         this.handleGetList = this.handleGetList.bind(this);
//         this.handleGet = this.handleGet.bind(this);
//     }
//     handleUpdate(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;

//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }
//     }

//     async update(id: string, cb?: any, cbparameters?: any, data?: any): Promise<any> {

//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         this.blogRepository.update(id, this.handleUpdate, this.prepareObjToUpdate(data), cbparameters);
//     }

//     handleDelete(result?: any, cbparameters?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters);
//         }
//     }

//     async delete(id: string, cb?: any, cbparameters?: any): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;

//         this.blogRepository.delete(id, this.handleDelete, cbparameters);
//     }



//     handleGet(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;
//         this.populateObject(rez.obj, result.obj, [
//             { key: 'status', type: 'status' },
//             { key: 'type', type: 'blogs' },
//         ]);
//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }

//     return rez;
//     }


//     async get(id: string, cb?: any, cbparameters?: any, idLanguage?: string): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         const t = await this.blogRepository.get(id, this.handleGet, cbparameters, idLanguage);
//         if (cb == undefined)
//         {
//             return this.handleGet(t);
//         }
//     }
    
//     handleGetList(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultListDTO();

//         rez = result;
//         if (result.err) return;

//         this.parseResultPopulate(result, rez, [
//             { key: 'status', type: 'status' },
//             { key: 'type', type: 'blogs' },
//         ]);

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }

//         return rez;
//     }

//     async getList(
//         cb?: any,
//         cbparameters?: any,
//         data?: RequestListDTO
//     ): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;
//         const t = await this.blogRepository.getList(this.handleGetList, cbparameters, data);
//         if (cb == undefined)
//         {
//             return this.handleGetList(t);
//         }
//     }

//     handleAdd(result?: any, cbparameters?: any, data?: any): any {
//         let rez = new ResultObjectDTO();
//         rez = result;
//         if (result.err) return;

//         if (cbparameters && cbparameters._cb) {
//             cbparameters._cb(rez, cbparameters, data);
//         }
//     }

//     prepareObjToAdd(obj: any): PostBlogDto {
//         const rez = new PostBlogDto();

//         rez.idlanguage = obj.idlanguage;
//         rez.type = obj.type;
//         rez.status = obj.status;

//         rez.name = obj.name;
//         rez.url = obj.url;
//         rez.title = obj.title;
//         rez.keymeta = obj.keymeta;
//         rez.descriptionmeta = obj.descriptionmeta;
//         rez.content = obj.content;
//         rez.idblogcategory = obj.idblogcategory;

//         return rez;
//     }

//     prepareObjToUpdate(obj: any): PostBlogDto {
//         const rez = new PostBlogDto();

//         rez.idlanguage = obj.idlanguage;
//         rez.type = obj.type;
//         rez.status = obj.status;
//         rez.idblog = obj.idblog;

//         rez.name = obj.name;
//         rez.url = obj.url;
//         rez.title = obj.title;
//         rez.keymeta = obj.keymeta;
//         rez.descriptionmeta = obj.descriptionmeta;
//         rez.content = obj.content;
//         rez.idblogcategory = obj.idblogcategory;

//         return rez;
//     }

//     async add(
//         cb?: any,
//         cbparameters?: any,
//         data?: any
//     ): Promise<any> {
//         cbparameters = cbparameters ? cbparameters : {};
//         cbparameters._cb = cb;

//         this.blogRepository.add(this.handleGetList, cbparameters, this.prepareObjToAdd(data));
//     }


//     getDefaultRequestListSelect(onPage?: number): RequestListDTO {
//         onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
//         if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
//         var defaultRequestList = new RequestListDTO();
//         defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
//         defaultRequestList.onpage = onPage;
//         var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
//         defaultRequestSortCriteria.asc = true;
//         defaultRequestSortCriteria.field = 'name';
//         defaultRequestList.sortcriteria = []
//         defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
//         return defaultRequestList;
//     }

//     getDefaultRequestList(obj: RequestListDTO): RequestListDTO {
//         if (
//             !obj.sortcriteria ||
//             !Array.isArray(obj.sortcriteria) ||
//             !obj.sortcriteria.length
//         ) {
//             var sobj = new RequestSortCriteriaDTO();
//             sobj.asc = true;
//             sobj.field = "name";

//             obj.sortcriteria = [];
//             obj.sortcriteria?.push(sobj);
//         }

//         return obj;
//     }
// }


import IService from "interfaces/iservice.interface";
import {BlogRepository} from "repositories/static/blog.repository";

import GeneralService from "services/globalgeneral.service";

import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { BlogDto } from "dto/static/blog.dto";


export class BlogService
  extends GeneralService<BlogRepository>
  implements IService
{
  constructor() {
    super(
      new BlogRepository(),
      [
        { key: "status", type: "status" },
        { key: "type", type: "blogs" },
      ],
      "name"
    );
  }

  prepareObjToUpdate(obj: any): BlogDto {
    const rez = new BlogDto();

    rez.idlanguage = obj.idlanguage;
    rez.type = obj.type;
    rez.status = obj.status;
    rez.idblog = obj.idblog;

    rez.name = obj.name;
    rez.url = obj.url;
    rez.title = obj.title;
    rez.keymeta = obj.keymeta;
    rez.descriptionmeta = obj.descriptionmeta;
    rez.content = obj.content;
    rez.idblogcategory = obj.idblogcategory;

    return rez;
  }
  getDefaultRequestListSelect(onPage?: number): RequestListDTO {
    onPage = onPage ?? Number(process.env.REACT_APP_ONPAGE);
    if (onPage === 0) onPage = Number(process.env.REACT_APP_ONPAGE);
    var defaultRequestList = new RequestListDTO();
    defaultRequestList.page = Number(process.env.REACT_APP_PAGE);
    defaultRequestList.onpage = onPage;
    var defaultRequestSortCriteria = new RequestSortCriteriaDTO()
    defaultRequestSortCriteria.asc = true;
    defaultRequestSortCriteria.field = 'name';
    defaultRequestList.sortcriteria = []
    defaultRequestList.sortcriteria?.push(defaultRequestSortCriteria);
    return defaultRequestList;
}
}