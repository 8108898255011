import React from "react";

import { BlogCategoryLevel } from "./BlogCategoryLevel";
import { Box } from "@mui/material";

type BlockBlogCategoryProps = {
    currentRoute: any;
    setBlogCategoryId: any;
    idTypeBlogCategory: string;
    setIdTypeBlogCategory: any;
}
const BlockBlogCategory: React.FC<BlockBlogCategoryProps> = ({
    setBlogCategoryId,
    currentRoute,
    idTypeBlogCategory,
    setIdTypeBlogCategory,
    ...props
}) => {


    return (
        <Box className="blogCategory">
            <BlogCategoryLevel 
            idTypeBlogCategory={idTypeBlogCategory} 
            currentRoute={currentRoute} 
            level={3} 
            idparent={""} 
            setBlogCategoryId={setBlogCategoryId} 
            setIdTypeBlogCategory={setIdTypeBlogCategory}
            />
        </Box>
    );

};

export { BlockBlogCategory };