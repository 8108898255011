import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import React, { useReducer, useState, useEffect } from "react";

import { useResource } from "hooks/useResource";
import { logger } from "tools/utils/logger";

import { SupportArticleDto } from "dto/support/supportarticle.dto";
import { SupportArticleService } from "services/support/supportarticle.service";
import { Types } from "tools/types/types";
import { SupportArticleCategoryDto } from "dto/support/supportarticlecategory.dto";
import { SupportArticleCategoryService } from "services/support/supportarticlecategory.service";
import { useCookies } from "react-cookie";
import { RouteTools } from "tools/utils/routetools";

interface SupportArticleState {
  articleHomePageObjects: Array<SupportArticleDto>;
  typeCategory: number;
  articleSupportCategoryHomeObjects: Array<SupportArticleCategoryDto>;
  selectedMainCategory: string;
  searchArticle: string;
  codeFilterCriteria: string;
  articleSearchObjects: Array<SupportArticleDto>;
  articleCategorySearchObjects: Array<SupportArticleCategoryDto>;
}
interface SupportArticleStateOther {
  isLoadingHomePageArticle: boolean;
  isLoadingHomePageArticleCategory: boolean;
  loadingCategorySearch: boolean;
  loadingSearch: boolean;
  total: number;
  totalPage: number;
  onPage: number;
}

interface StateContext extends SupportArticleState, SupportArticleStateOther {}

interface SupportArticleActions {
  getHomePageArticles: () => void;
  setTypeCategory: (typeCategory: number) => void;
  setIsLoadingHomePageArticle: (isLoading: boolean) => void;
  getHomePageCategoryArticles: () => void;
  setIsLoadingHomePageArticleCategory: (isLoading: boolean) => void;
  setSelectedMainCategory: (selectedMainCategory: string) => void;
  getMainCategoryByCode: (code: string) => SupportArticleCategoryDto | null;
  setSearchArticle: (searchArticle: string) => void;
  setCodeFilterCriteria: (codeFilterCriteria: string) => void;
  setLoadingCategorySearch: (loading: boolean) => void;
  setLoadingSearch: (loading: boolean) => void;
  resetFilters: () => void;
  handleSearch: () => void;
  getArticlesListSearch: () => void;
  setOnPage: (onPage: number) => void;
  getArticlesCategoryListSearch: () => void;
  processTotalNumberOfArticles: () => number;
}

export const SupportArticleContext = React.createContext({
  state: {} as StateContext,
  actions: {} as SupportArticleActions,
});

interface ProviderProps {
  children: React.ReactNode;
}

interface Action {
  type: string;
  payload?: any;
}

const reducer = (state: SupportArticleState, action: Action) => {
  switch (action.type) {
    case "set_article_home_page_objects": {
      return {
        ...state,
        articleHomePageObjects: action.payload.objects,
      };
    }
    case "set_type_category": {
      return {
        ...state,
        typeCategory: action.payload.typeCategory,
      };
    }
    case "set_article_support_category_objects": {
      return {
        ...state,
        articleSupportCategoryHomeObjects: action.payload.objects,
      };
    }
    case "set_selected_main_category": {
      return {
        ...state,
        selectedMainCategory: action.payload.selectedMainCategory,
      };
    }
    case "set_search_article": {
      return {
        ...state,
        searchArticle: action.payload.searchArticle,
      };
    }
    case "set_code_filter_criteria": {
      return {
        ...state,
        codeFilterCriteria: action.payload.codeFilterCriteria,
      };
    }
    case "set_article_search_objects": {
      return {
        ...state,
        articleSearchObjects: action.payload.objects,
      };
    }

    case "set_article_category_search_objects": {
      return {
        ...state,
        articleCategorySearchObjects: action.payload.objects,
      };
    }
    case "reset_filters": {
      return {
        ...state,
        searchArticle: "",
        codeFilterCriteria: "",
      };
    }

    default:
      return state;
  }
};

const supportArticleService = new SupportArticleService();
const supportArticleCategoryService = new SupportArticleCategoryService();

export const SupportArticleProvider: React.FC<ProviderProps> = ({
  children,
}) => {
  const { CC, currentRoute } = useResource();
  const [cookies, setCookies] = useCookies();
  const [total, setTotal] = useState<number>(-1);
  const [totalPage, setTotalPage] = useState<number>(-1);

  const [onPage, setOnPage] = useState<number>(
    parseInt(CC("ON_PAGE_SUPPORT_ARTICLE_SEARCH_PAGE", "10"))
  );
  const processDefaultTypeCategory = () => {
    let typeCategory = Types.TYPE_SUPPORT_ARTICLE_CATEGORY_STUDENT;
    if (cookies?.typeCategory) {
      typeCategory = parseInt(cookies.typeCategory);
    }
    return typeCategory;
  };
  const [state, dispatch] = useReducer(reducer, {
    articleHomePageObjects: [],
    typeCategory: processDefaultTypeCategory(),
    articleSupportCategoryHomeObjects: [],
    selectedMainCategory: "",
    searchArticle: "",
    codeFilterCriteria: "",
    articleSearchObjects: [],
    articleCategorySearchObjects: [],
  });

  const [isLoadingHomePageArticle, setIsLoadingHomePageArticle] =
    useState(true);
  const [
    isLoadingHomePageArticleCategory,
    setIsLoadingHomePageArticleCategory,
  ] = useState(true);
  const [loadingCategorySearch, setLoadingCategorySearch] = useState(true);
  const [loadingSearch, setLoadingSearch] = useState(true);

  const resetFilters = () => {
    dispatch({ type: "reset_filters" });
  };

  const setSearchArticle = (searchArticle: string) => {
    dispatch({ type: "set_search_article", payload: { searchArticle } });
  };

  const setCodeFilterCriteria = (codeFilterCriteria: string) => {
    dispatch({
      type: "set_code_filter_criteria",
      payload: { codeFilterCriteria },
    });
  };

  const setSelectedMainCategory = (selectedMainCategory: string) => {
    dispatch({
      type: "set_selected_main_category",
      payload: { selectedMainCategory },
    });
  };

  const getHomePageArticles = () => {
    setIsLoadingHomePageArticle(true);
    const req = new RequestListDTO();
    const sort = new RequestSortCriteriaDTO();
    sort.field = "ordercriteria";
    sort.asc = true;
    req.sortcriteria = [sort];
    req.page = 1;
    req.onpage = parseInt(CC("FAQ_ON_PAGE_SUPPORT_ARTICLE_HOME_PAGE", "9"));
    const filter = new RequestFilterDTO();
    filter.field = "type";
    filter.values = [state.typeCategory.toString()];

    req.filters = [filter];

    supportArticleService.getList(handleGetHomePageArticles, {}, req);
  };

  const getArticlesListSearch = () => {
    setIsLoadingHomePageArticle(true);
    const req = new RequestListDTO();
    const sort = new RequestSortCriteriaDTO();
    sort.field = "ordercriteria";
    sort.asc = true;
    req.sortcriteria = [sort];
    req.page = 1;
    //logger("onPage", onPage, CC("ON_PAGE_SUPPORT_ARTICLE_SEARCH_PAGE", "10"));

    req.onpage = onPage;

    req.filters = [];
    let filter = new RequestFilterDTO();
    filter.field = "searchvalue";
    filter.values = [state.searchArticle];
    req.filters.push(filter);
    filter = new RequestFilterDTO();
    filter.field = "maincode";
    filter.values = [state.codeFilterCriteria];
    req.filters.push(filter);

    const urlPart = RouteTools.prepareListLocation(req);

    setLoadingSearch(true);
    supportArticleService.getListSearch(handleArticlesListSearch, {}, req);
    RouteTools.setHistory("supportarticlesearch" + urlPart, {});
  };

  useEffect(() => {
    if (!currentRoute) return;
    //logger("currentRouteArticle", currentRoute);
    if (currentRoute._mainobject !== "supportarticlesearch") return;

    const reqListRoute = RouteTools.prepareListRequest(currentRoute, cookies);
    if (reqListRoute.onpage) {
      const onPage =
        reqListRoute.onpage === 1
          ? parseInt(CC("ON_PAGE_SUPPORT_ARTICLE_SEARCH_PAGE", "10"))
          : reqListRoute.onpage;
      setOnPage(onPage);
    }
    if (reqListRoute.filters && reqListRoute.filters.length > 0) {
      const existingFilter = reqListRoute.filters.find(
        (filter) => filter.field === "searchvalue"
      );
      if (existingFilter) {
        if (existingFilter.values && existingFilter.values.length > 0)
          setSearchArticle(existingFilter.values[0]);
      }
      const existingFilterCode = reqListRoute.filters.find(
        (filter) => filter.field === "maincode"
      );
      if (existingFilterCode) {
        if (existingFilterCode.values && existingFilterCode.values.length > 0)
          setCodeFilterCriteria(existingFilterCode.values[0]);
      }
    }
  }, [currentRoute]);

  const getArticlesCategoryListSearch = () => {
    const req = new RequestListDTO();
    const sort = new RequestSortCriteriaDTO();
    sort.field = "ordercriteria";
    sort.asc = true;
    req.sortcriteria = [sort];
    req.page = 1;
    req.onpage = parseInt(
      CC("ON_PAGE_SUPPORT_ARTICLE_CATEGORY_SEARCH_PAGE", "100")
    );
    req.filters = [];
    let filter = new RequestFilterDTO();
    filter.field = "searcharticle";
    filter.values = [state.searchArticle];
    req.filters.push(filter);

    setLoadingCategorySearch(true);
    supportArticleCategoryService.getListSearch(
      handleArticlesCategoryListSearch,
      {},
      req
    );
  };

  const handleSearch = () => {
    // getArticlesCategoryListSearch();
    // getArticlesListSearch();
    RouteTools.setHistory("supportarticlesearch", {});
  };

  const processTotalNumberOfArticles = () => {
    if (!state.articleCategorySearchObjects) return 0;
    if (!state.articleCategorySearchObjects.length) return 0;
    const total = state.articleCategorySearchObjects.reduce(
      (prev: number, current: SupportArticleCategoryDto) => {
        let num = 0;
        if (!current) num = 0;
        else if (!current.numberofarticles) num = 0;
        else num = current.numberofarticles;
        return prev + num;
      },
      0
    );
    return total;
  };
  const handleArticlesCategoryListSearch = (result: ResultListDTO) => {
    if (!result) return;
    if (result.err) return;
    const objects = result.objects ?? [];
    dispatch({
      type: "set_article_category_search_objects",
      payload: { objects },
    });
    setLoadingCategorySearch(false);
  };

  const handleArticlesListSearch = (result: ResultListDTO) => {
    if (!result) return;
    if (result.err) return;
    const objects = result.objects ?? [];
    const total = result.total !== undefined ? result.total : -1;
    const totalPage = result.totalpages !== undefined ? result.totalpages : -1;
    setTotal(total);
    setTotalPage(totalPage);
    dispatch({ type: "set_article_search_objects", payload: { objects } });
    setLoadingSearch(false);
  };

  const handleGetHomePageArticles = (result: ResultListDTO) => {
    if (!result) return;
    if (result.err) return;
    const objects = result.objects ?? [];

    dispatch({ type: "set_article_home_page_objects", payload: { objects } });
    setIsLoadingHomePageArticle(false);
  };

  const setTypeCategory = (typeCategory: number) => {
    setCookies("typeCategory", typeCategory.toString());
    dispatch({ type: "set_type_category", payload: { typeCategory } });
  };

  const getHomePageCategoryArticles = () => {
    const req = new RequestListDTO();
    const sort = new RequestSortCriteriaDTO();
    sort.field = "ordercriteria";
    sort.asc = true;
    req.sortcriteria = [sort];
    req.page = 1;
    req.onpage = parseInt(CC("FAQ_ON_PAGE_SUPPORT_ARTICLE_HOME_PAGE", "100"));
    const filter = new RequestFilterDTO();
    filter.field = "type";
    filter.values = [state.typeCategory.toString()];
    const filter2 = new RequestFilterDTO();
    filter2.field = "idparent";
    filter2.values = [""];
    req.filters = [filter, filter2];
    setIsLoadingHomePageArticleCategory(true);
    supportArticleCategoryService.getList(
      handleGetHomePageCategoryArticles,
      {},
      req
    );
  };

  const handleGetHomePageCategoryArticles = (result: ResultListDTO) => {
    if (!result) return;
    if (result.err) return;
    const objects = result.objects ?? [];
    dispatch({
      type: "set_article_support_category_objects",
      payload: { objects },
    });
    setIsLoadingHomePageArticleCategory(false);
  };

  useEffect(() => {
    getHomePageCategoryArticles();
  }, [state.typeCategory]);

  const getMainCategoryByCode = (code: string) => {
    const mainCategory = state.articleSupportCategoryHomeObjects.find(
      (x: SupportArticleCategoryDto) => x.maincode === code
    );
    if (mainCategory) return mainCategory;
    else return null;
  };

  return (
    <SupportArticleContext.Provider
      value={{
        state: {
          ...state,
          isLoadingHomePageArticle,
          isLoadingHomePageArticleCategory,
          loadingCategorySearch,
          loadingSearch,
          total,
          totalPage,
          onPage,
        },
        actions: {
          getHomePageArticles,
          setTypeCategory,
          setIsLoadingHomePageArticle,
          getHomePageCategoryArticles,
          setIsLoadingHomePageArticleCategory,
          setSelectedMainCategory,
          getMainCategoryByCode,
          setSearchArticle,
          setCodeFilterCriteria,
          setLoadingCategorySearch,
          setLoadingSearch,
          resetFilters,
          handleSearch,
          getArticlesListSearch,
          setOnPage,
          getArticlesCategoryListSearch,
          processTotalNumberOfArticles,
        },
      }}
    >
      {children}
    </SupportArticleContext.Provider>
  );
};
