// import React from "react";
// import { useResource } from "hooks/useResource";
// import Stack from "@mui/material/Stack";

// import Box from "@mui/material/Box";
// import { logger } from "tools/utils/logger";
// import { MyButton } from "components/elements/button/MyButton";
// import { RouteTools } from "tools/utils/routetools";
// // import { GoogleIcon } from "components/general/GoogleIcon";
// // import AppleIcon from "@mui/icons-material/Apple";
// import FacebookIcon from "@mui/icons-material/Facebook";

// import { MyGoogleLoginButton } from "./openids/MyGoogleLoginButton";

// type AuthMethodsBlockProps = {
//   isLoginPage: boolean;
// };

// const AuthMethodsBlock: React.FC<AuthMethodsBlockProps> = ({
//   isLoginPage,
//   ...props
// }) => {
//   const { LL } = useResource();

//   const goToSignUp = () => {
//     RouteTools.setHistory("/signup", {});
//   };

//   const goToLogin = () => {
//     RouteTools.setHistory("/login", {});
//   };

//   const processButtons = () => {
//     return isLoginPage ? (
//       <MyButton
//         type="button"
//         children={LL("Bt_Register")}
//         cb={goToSignUp}
//         className="btn"
//         color="primary"
//         variant="outlined"
//         fullWidth
//       />
//     ) : (
//       <MyButton
//         type="button"
//         children={LL("Bt_Login")}
//         cb={goToLogin}
//         variant="outlined"
//         className="btn"
//         color="primary"
//         fullWidth
//       />
//     );
//   };
//   return (
//     <Box>
//       {processButtons()}

//       <Stack
//         spacing={2}
//         alignItems="center"
//         direction="row"
//         sx={{ my: 4 }}
//         justifyContent="center"
//       >
//         <MyButton
//           type="button"
//           children={LL("Bt_Facebook")}
//           cb={() => {
//             //logger("Facebook");
//           }}
//           variant="text"
//           color="primary"
//           className="btn-auth-social"
//           startIcon={<FacebookIcon className="FacebookIcon" />}
//         />
//         <MyGoogleLoginButton />
//         {/* <MyButton
//           type="button"
//           children={LL("Bt_Apple")}
//           cb={() => {
//             //logger("Apple");
//           }}
//           variant="text"
//           color="primary"
//           className="btn-auth-social"
//           startIcon={<AppleIcon className="AppleIcon" />}
//         /> */}
//       </Stack>
//     </Box>
//   );
// };

// export { AuthMethodsBlock };


import React from "react";
import { useResource } from "hooks/useResource";

import Stack from "@mui/material/Stack";

import Box from "@mui/material/Box";

import { MyButton } from "components/elements/button/MyButton";
import { RouteTools } from "tools/utils/routetools";

import { MyGoogleLoginButton } from "./openids/MyGoogleLoginButton";
import { MyFaceBookLoginButton } from "./openids/MyFaceBookLoginButton";

type Props = {
  isLoginPage: boolean;
};

const AuthMethodsBlock: React.FC<Props> = ({
  isLoginPage,
}) => {
  const { LL,urlToSignUp } = useResource();

  const goToSignUp = () => {
    window.open(urlToSignUp, "_blank");
  };

  const goToLogin = () => {
    RouteTools.setHistory("/login", {});
  };

  const processButtons = () => {
    return isLoginPage ? (
      <MyButton
        type="button"
        children={LL("Bt_Register")}
        cb={goToSignUp}
        className="btn"
        color="primary"
        variant="outlined"
        fullWidth
      />
    ) : (
      <MyButton
        type="button"
        children={LL("Bt_Login")}
        cb={goToLogin}
        variant="outlined"
        className="btn"
        color="primary"
        fullWidth
      />
    );
  };
  return (
    <Box>
      {processButtons()}

      <Stack
        spacing={2}
        alignItems="center"
        direction="row"
        sx={{ my: 4 }}
        justifyContent="center"
      >
        <MyFaceBookLoginButton />
        <MyGoogleLoginButton />
      </Stack>
    </Box>
  );
};

export { AuthMethodsBlock };
