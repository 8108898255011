import React, { useState } from "react";
import { TopLogoBlock } from "./TopLogoBlock";
import PageComponentProps from "interfaces/pagecomponentprops.interface";

import { SignUpBlock } from "components/user/SignUpBlock";
import { SignInBlock } from "components/user/SignInBlock";

import { TopBar } from "components/general/TopBar";

import Grid from "@mui/material/Grid";

import { useAuth } from "hooks/useAuth";

import { MainDrawer } from "components/elements/Drawer/MainDrawer";
import { IconButton } from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import { ButtonBackMainSite } from "../elements/button/BottomBackMainSite";

const Header: React.FC<PageComponentProps> = ({ currentRoute }) => {
  const { user } = useAuth();

  const [openDrawer, setOpenDrawer] = useState(false);
  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const processUserActionBlock = () => {
    if (!user) {
      return (
        <>
          <SignUpBlock currentRoute={currentRoute} />
          <SignInBlock currentRoute={currentRoute} />
        </>
      );
    } else {
      return <TopBar currentRoute={currentRoute} />;
    }
  };

  return (
    <Grid container className="row headerStyle">
      <Grid item className="el1" xs={1}>
        <IconButton onClick={handleOpenDrawer}>
          <MenuIcon />
        </IconButton>
        <MainDrawer
          open={openDrawer}
          setOpen={setOpenDrawer}
          currentRoute={currentRoute}
        />
      </Grid>
      <Grid item lg={3} xs={9} className="el2">
        <ButtonBackMainSite currentRoute={currentRoute} />
      </Grid>
      <Grid item lg={6} xs={11} className="el3">
        <TopLogoBlock currentRoute={currentRoute} />
      </Grid>
      <Grid
        item
        lg={3}
        className="el4"
        display="flex"
        justifyContent="flex-end"
      >
        {processUserActionBlock()}
      </Grid>
    </Grid>
  );
};

export { Header };
