import React, { useState, useEffect } from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { FAQDto } from 'dto/static/faq.dto'
import { MyButton } from "components/elements/button/MyButton";
import { CommonTools } from 'tools/utils/commontools';
import { ComponentCommonTools } from 'tools/utils/componentcommontools'
import { useResource } from "hooks/useResource";
import { RouteTools } from "tools/utils/routetools";
import { Box, Stack, Divider, ListItemButton, ListItemText, Link } from "@mui/material";

import { logger } from "tools/utils/logger";

import { MyNavBt } from "components/elements/button/MyNavBt";
import { HtmlTag } from "components/elements/display/HtmlTag";

type FAQItemProps = {
    obj: FAQDto;
}

const FAQItem: React.FC<FAQItemProps> = ({ obj, ...props }) => {

    const { LL } = useResource();

    const processAnswer = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty("answer")) return <></>;
        if (!obj.answer) return <></>;
        return <HtmlTag content={obj.answer} />;
      };

    const processItem = () => {
        if (!obj) return <></>
        if (!obj.hasOwnProperty('id')) return <></>
        if (!obj.id) return <></>

        return (<Box id={obj.id}>
            <Box>
                <Typography variant="h2" >
                    {ComponentCommonTools.processObjectField(obj, 'question')}
                </Typography>
            </Box>
            <Divider />
            <Box>
                {processAnswer()}
            </Box>
        </Box>)
    }



    return processItem();
};

export { FAQItem };
