import Idto from "interfaces/idto.interface";
export default class SelectOptions implements Idto {
    value?: number | string;
    label?: string;
    otherData?:any;

    constructor(value?: number | string, label?: string, otherData?: any) {
        this.value = value || "";
        this.label = label || "";
        this.otherData = otherData || undefined;
      }
}