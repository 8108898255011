import React from "react";
import FacebookLogin from "react-facebook-login";
import { useAuth } from "hooks/useAuth";
import SocialSignInDto from "dto/auth/socialsignin.dto";
import { SocialIdentifier } from "tools/types/socialidentifier";

import { Config } from "tools/utils/config";

const MyFaceBookLoginButton: React.FC = () => {
  const { signInSocial } = useAuth();

  const responseFacebook = (response: any) => {
    // console.log(responseFacebook, response);
    // logger("responseFacebook", response);
    const obj = new SocialSignInDto();

    obj.socialid = response.id;
    obj.socialuseridentifier = response.email;
    obj.socialidentifier = SocialIdentifier.FACEBOOK.toString();
    obj.socialtoken = response.signedRequest;
    obj.requestObject = response;
    signInSocial(obj);
  };

  const errorMessage = (e?: any) => {};

  const appId: string = Config.FB_CONSOLE_APP_ID;

  return (
    <FacebookLogin
      appId={appId}
      autoLoad={false}
      fields={"name,email,picture"}
      callback={responseFacebook}
      cssClass="btn-auth-social"
      icon="fa-facebook"
      onFailure={errorMessage}
    />
  );
};

export { MyFaceBookLoginButton };
