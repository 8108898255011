import React from "react";
import { Box, Typography } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import { LocalStorageApi } from "api/localstorage.api";


const ServerError: React.FC = () => {
  const handleReload = () => {
    LocalStorageApi.saveObject("reload_count", []);
    window.location.reload();
  };
  return (
    
    <Box className="dialogPages">

      <Box className="imageErrorBox"></Box>
      <Box className="errorText">Server Error</Box>
      <Box className="errorTextSmall">Please wait a bit and try again.</Box>
      <Box mt={10} className="">
        <MyButton cb={handleReload} className="errorBoxButton">
          <Typography className="textButton">Reload Page</Typography>
        </MyButton>
      </Box>
    </Box>
  );
};

export { ServerError };
