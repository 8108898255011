import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hooks/useResource";

import {SiteSocialService} from "services/static/sitesocial.service";
import { CommonTools } from 'tools/utils/commontools';
import RequestListDTO from "dto/app/requestlist.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import { SiteSocialDto } from "dto/static/sitesocial.dto";
import Box from '@mui/material/Box';
import { SocialItem } from "components/static/social/SocialItem";
import Stack from '@mui/material/Stack';
const siteSocialService = new SiteSocialService();

const SocialBlock: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  const { saveCache, getCache ,LL} = useResource();

  const [data, setData] = useState<ResultListDTO>(new ResultListDTO());
  const [rows, setRows] = useState<Array<SiteSocialDto>>(new Array<SiteSocialDto>());

  const [total, setTotal] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  const [responseParams, setResponseParams] = useState<RequestListDTO>(
    new RequestListDTO()
  );

  const [reqList, setReqList] = useState<RequestListDTO>(new RequestListDTO());

  const [loading, setLoading] = useState<boolean>(true);

  const loadObjects = (data: ResultListDTO) => {
    CommonTools.processListLoadObjects(
      data,
      setData,
      setRows,
      setTotal,
      setTotalPage,
      setResponseParams
    );
  };
  const getList = () => {
    setLoading(true);

    siteSocialService.getList(loadObjects, {}, reqList);
  };

  const getReqList = () => {
    let reqList = siteSocialService.getDefaultRequestListSelect(100);

    setReqList(reqList);
  };

  useEffect(() => {
    getReqList();
  }, []);

  useEffect(() => {
    if (CommonTools.atLeastOneFieldDefined(reqList)) {
      getList();
    }
  }, [reqList]);

  useEffect(() => {
    checkIsLoading();
  }, [data, rows, reqList, total, totalPage]);

  const checkIsLoading = () => {
    const load: boolean = CommonTools.checkIsLoading(
      reqList,
      responseParams,
      data,
      total,
      totalPage,
      rows
    );
    setLoading(load);
  };

  const processSiteSocialItem = (obj: SiteSocialDto, index: number) => {
    if (!obj) return <></>

    return <Box key={index}><SocialItem obj={obj} /></Box>;
  };

  const processSiteSocialList = () => {
    if (!rows.length) return <></>;

    return (
      <Stack direction="row" spacing={1} sx={{mt:1}}>
        {rows.map((item: SiteSocialDto, i: number) => {
          return processSiteSocialItem(item, i);
        })}
      </Stack>
    );
  };

  return (<>{LL('Social')} {processSiteSocialList()}</>);
};

export { SocialBlock };
