import React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Unstable_Grid2";

type Props = {
  lines?: number;
  width?: number;
};

const SkeletonSearchResult: React.FC<Props> = ({ lines }) => {
  const gridItem = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        // marginX: "auto",
        flexDirection: "row",
        width: "100%",
        height: "auto",
        gap: 2,
        px: 5,
        mb: 6,
      }}
    >
      <Grid
        xs={12}
        container
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "start",
          flexDirection: "column",
        }}
      >
        <Skeleton variant="text" sx={{ fontSize: "1.25rem", width: "40%" }} />
        <Skeleton
          variant="text"
          sx={{ fontSize: "1rem", width: "90%", mt: 2 }}
        />
        <Skeleton variant="text" sx={{ fontSize: "1rem", width: "30%" }} />
        <Skeleton
          variant="text"
          sx={{ fontSize: "0.75rem", width: "20%", minWidth: "60px", mt: 2 }}
        />
      </Grid>
    </Box>
  );

  return (
    <Box sx={{ width: "100%", height: "auto" }} className="">
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "start",
          alignContent: "center",
          //   margin: "auto",
          padding: "20px 0px 20px 0px",
        }}
      >
        <Grid
          container
          xs={12}
          sx={{ maxWidth: "1522px", width: "auto" }}
          spacing={2}
        >
          {Array(lines)
            .fill(null)
            .map((_, index) => (
              <React.Fragment key={index}>{gridItem}</React.Fragment>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

export { SkeletonSearchResult };
