import Idto from "interfaces/idto.interface";

export class PostLabelDto implements Idto {
  identifier?: string;
  type?: number;
  status?: number;
  value?: string;
  idlanguage?: string;
  idlabelvalue?: string;
  idlabel?: string;
  _nonupdate?: string;
}
