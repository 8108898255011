import { useContext } from "react";
import { ResourceContext } from "providers/ResourceProvider";
import { Config } from "tools/utils/config";

export function useResource() {
  const mainUrl = Config.WWW_SERVER;
  const urlToSignUp = mainUrl ? mainUrl + "/signup" : "";
  const urlForgotPassword = mainUrl ? mainUrl + "/forgotpassword" : "";
  const state = useContext(ResourceContext);
  return { ...state, urlToSignUp, urlForgotPassword };
}
