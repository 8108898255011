import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  Divider,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import { useResource } from "hooks/useResource";
import { useAuth } from "hooks/useAuth";
import LogoutIcon from "@mui/icons-material/Logout";

const drawerWidth = 300;

type UserDrawerProps = {
  open: boolean;
  setOpen: any;
  setOpenMainDrawer: any;
};
const UserDrawer: React.FC<UserDrawerProps> = ({
  open,
  setOpen,
  setOpenMainDrawer,

  ...props
}) => {
  const { user, logout, usersettings } = useAuth();
  const { LL } = useResource();

  const handleClose = () => {
    setOpenMainDrawer(false);
    setOpen(false);
  };

  const handleCloseUserDrawer = () => {
    setOpen(false);
  };

  const processCloseButton = () => {
    return (
      <IconButton onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    );
  };

  const processCloseButtonUserDrawer = () => {
    return (
      <IconButton onClick={handleCloseUserDrawer}>
        <ArrowBackIosNewIcon />
      </IconButton>
    );
  };

  const handleLogOut = () => {
    handleClose();
    logout();
  };

  const processUserName = () => {
    if (!usersettings) return null
    if (!usersettings.hasOwnProperty("name")) return null
    if (!usersettings.hasOwnProperty("surname")) return null
    if (!usersettings.name) return null
    if (!usersettings.surname) return null

    return (
      <Typography color="primary">
        {usersettings.name} {usersettings.surname}
      </Typography>
    );
  };

  const processUserOptions = () => {
    return (
      <List sx={{ p: 0, m: 0 }}>
        {/* {options.map((option, index) => (
                    <List key={index} sx={{ p: 0, m: 0 }}>
                        <Divider />
                        {option.options.map((option, index) => (

                            <ListItem
                                key={index}
                                additionalOnClick={handleClose}
                                component={MyNavBt}
                                href={processHref(option)}
                                sx={{ p: 0, m: 0 }}
                                className="linkSecond"
                            >
                                <ListItemButton >
                                    {processName(option)}
                                </ListItemButton>
                            </ListItem>
                        ))
                        }
                    </List>)
                )} */}
      </List>
    );
  };
  
  const processUserActions = () => {
    if (user) {
      return (
        <Box role="presentation" sx={{ width: drawerWidth }}>
          <List sx={{ p: 0, m: 0 }}>
            <ListItem>
              <ListItemText>{processUserName()}</ListItemText>
            </ListItem>

            <Divider />
          </List>

          {processUserOptions()}

          <Divider sx={{ my: 1 }} />
          <List>
            <ListItem onClick={handleLogOut} sx={{ p: 0, m: 0 }}>
              <ListItemButton>
                <ListItemIcon sx={{ minWidth: "15px", mr: 1 }}>
                  <LogoutIcon className="iconRight" />
                </ListItemIcon>
                {LL("Logout")}
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      );
    }
    return null
  };

  return (
    <Box>
      <Drawer open={open} onClose={handleClose}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          p={1}
        >
          {processCloseButtonUserDrawer()}
          {processCloseButton()}
        </Stack>
        <Divider />
        {processUserActions()}
      </Drawer>
    </Box>
  );
};

export { UserDrawer };
