import React, { useContext, useEffect, useState } from "react";
import { useResource } from "hooks/useResource";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { CommonTools } from "tools/utils/commontools";
import SearchIcon from "@mui/icons-material/Search";

import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { SupportArticleContext } from "context/supportarticle.context";
import { AdvertisementDto } from "dto/static/advertisement.dto";

type Props = {
  obj: AdvertisementDto;
};
const AdvertisementSearchItem: React.FC<Props> = ({
  obj,
  ...props
}) => {
  const { LL } = useResource();
  const [imgUrl, setImgUrl] = useState<string>("");
  const [fullUrl, setFullUrl] = useState<string>("");
  const {
    actions: { setSearchArticle, handleSearch },
  } = useContext(SupportArticleContext);
  const prepareGallery = () => {
    if (!obj) return;
    if (!obj.hasOwnProperty("gallery")) return;
    if (!obj.gallery) return;
    if (obj.gallery.length === 0) return;
    const galleryObj = CommonTools.getGalleryObjectLogo(obj.gallery);
    if (!galleryObj) return;
    CommonTools.setFileUrlsGallery(
      galleryObj,
      setImgUrl,
      setFullUrl,
      2000,
      694
    );
  };

  useEffect(() => {
    prepareGallery();
  }, [obj]);

  const [search, setSearch] = useState("");
  const handleSubmit = (event: any) => {
    event.preventDefault();
    handleSearch();
    setSearch("");
  };

  const handleChange = (event: any) => {
    setSearch(event.target.value);
    setSearchArticle(event.target.value);
  };

  const processItem = () => {
    if (!obj) return null

    return (
      <Box
        className="bannerSearch"
        sx={{
          backgroundImage: `url(${imgUrl})`,
        }}
      >
        <Box className="container">
          <Grid container>
            <Grid item md={5} sm={12}>
              <Typography className="name">
                {ComponentCommonTools.processObjectField(obj, "name")}
              </Typography>
              <Typography className="description" my={4}>
                {ComponentCommonTools.processObjectField(obj, "description")}
              </Typography>
              <Box>
                <form onSubmit={handleSubmit}>
                  <Box className="searchForm">
                    <TextField
                      label={LL("Search_for_solution")}
                      onFocus={handleChange}
                      onChange={handleChange}
                      value={search}
                      fullWidth
                      sx={{
                        "& label.Mui-focused": {
                          display: "none",
                        },
                        "& .MuiOutlinedInput-root.Mui-focused": {
                          "& > fieldset": {
                            borderColor: "none",
                            borderWidth: "0px",
                          },
                        },
                        "& .MuiOutlinedInput-root": {
                          "& > fieldset": {
                            border: "none",
                          },
                        },
                      }}
                      variant="outlined"
                      className="input"
                      {...props}
                    />
                    <Button type="submit">
                      {ComponentCommonTools.processObjectField(obj, "clabt")}
                      <SearchIcon />
                    </Button>
                  </Box>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };

  return processItem();
};

export { AdvertisementSearchItem };
