import React, {  useEffect,  useState } from "react";
import { useResource } from "hooks/useResource";

import {BlogCategoryService} from "services/static/blogcategory.service";

import { BlogCategoryDto } from "dto/static/blogcategory.dto";

import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools/types/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";

import Skeleton from '@mui/material/Skeleton';

import { BlogCategoryItem } from "./BlogCategoryItem";
const blogCategoryService = new BlogCategoryService();

type BlogCategoryLevelProps = {
    currentRoute: any;
    level: number;
    idparent: string;
    setBlogCategoryId?: any;
    setIdTypeBlogCategory:any
    idTypeBlogCategory: string;
}
const BlogCategoryLevel: React.FC<BlogCategoryLevelProps> = ({
    currentRoute,
    level,
    idparent,
    setBlogCategoryId,
    setIdTypeBlogCategory,
    idTypeBlogCategory,
    ...props
}) => {
    const { saveCache, getCache } = useResource();

    const [isLoading, setIsLoading] = useState(true);
    const [objects, setObjects] = useState<Array<BlogCategoryDto>>([]);


    const getList = async () => {
        
        const reqList = new RequestListDTO();
        reqList.onpage = 100;
        reqList.page = 1;
        reqList.filters = [];

        let f: RequestFilterDTO;

        f = new RequestFilterDTO();
        f.field = "status";
        f.values = [];
        f.values.push(Status.ACTIVE.toString());
        reqList.filters.push(f);

        f = new RequestFilterDTO();
        f.field = "idparent";
        f.values = [];
        f.values.push(idparent);
        reqList.filters.push(f);
        
        f = new RequestFilterDTO();
        f.field = "idtypeblogcategory";
        f.values = [idTypeBlogCategory];
        reqList.filters.push(f);

        const cd = getCache(JSON.stringify(reqList));

        if (cd !== false) {
            setObjects(cd);
            setIsLoading(false);
            return;
        }

        setIsLoading(true);

        const data = await blogCategoryService.getList(undefined, {}, reqList);

        const rez = !data || !data.objects ? [] : data.objects;

        setObjects(rez);
        setIsLoading(false);


        saveCache(rez, JSON.stringify(reqList))
    };

    useEffect(() => {
        getList();
    }, [idparent,idTypeBlogCategory]);

    const processBlogCategoryItem = (obj: BlogCategoryDto, index: number) => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('id')) return <></>;
        if (!obj.id) return <></>;
        const hasChildren = obj.hasOwnProperty('haschildren') && obj.haschildren !== undefined ? obj.haschildren : false;

        return (
            <BlogCategoryItem
                currentRoute={currentRoute}
                level={level} obj={obj}
                hasChildren={hasChildren}
                setBlogCategoryId={setBlogCategoryId}
                setIdTypeBlogCategory={setIdTypeBlogCategory}
                idTypeBlogCategory={idTypeBlogCategory}
            />

        )
    };


    const processBlogCategoryList = () => {
        if (!objects.length) return <></>;

        return (
            <>
                {objects.map((item: BlogCategoryDto, i: number) => {
                    return processBlogCategoryItem(item, i);
                })}
            </>
        );
    };

    return isLoading ? <Skeleton /> : processBlogCategoryList();
};

export { BlogCategoryLevel };
