import React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Unstable_Grid2";
import { useResponsive } from "hooks/useResponsive";

type Props = {
  lines?: number;
  width?: number;
};

const SkeletonSubject: React.FC<Props> = ({ lines }) => {
  const { matchesMD } = useResponsive();

  let gridItemsCount;
  if (matchesMD) {
    gridItemsCount = 4;
  } else {
    gridItemsCount = 12;
  }

  const gridItem = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        // marginX: "auto",
        flexDirection: "row",
        width: "100%",
        gap: 2,
        px: 5,
      }}
    >
      <Grid xs={12} container spacing={2} sx={{}}>
        <Grid
          xs={gridItemsCount}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            mb: { xs: 6, md: 2 },
          }}
        >
          <Skeleton
            variant="text"
            sx={{ fontSize: "1.25rem", width: "97%", mb: 2 }}
          />
          <Skeleton variant="text" sx={{ fontSize: "1rem", width: "90%" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem", width: "93%" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem", width: "96%" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem", width: "43%" }} />
        </Grid>
        <Grid
          xs={gridItemsCount}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            mb: { xs: 6, md: 2 },
          }}
        >
          <Skeleton
            variant="text"
            sx={{ fontSize: "1.25rem", width: "97%", mb: 2 }}
          />
          <Skeleton variant="text" sx={{ fontSize: "1rem", width: "90%" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem", width: "93%" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem", width: "96%" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem", width: "43%" }} />
        </Grid>
        <Grid
          xs={gridItemsCount}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            mb: { xs: 6, md: 2 },
          }}
        >
          <Skeleton
            variant="text"
            sx={{ fontSize: "1.25rem", width: "97%", mb: 2 }}
          />
          <Skeleton variant="text" sx={{ fontSize: "1rem", width: "90%" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem", width: "93%" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem", width: "96%" }} />
          <Skeleton variant="text" sx={{ fontSize: "1rem", width: "43%" }} />
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <Box sx={{ width: "100%", height: "auto" }} className="">
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "start",
          alignContent: "center",
          padding: "20px 0px 20px 0px",
        }}
      >
        <Grid
          container
          xs={12}
          sx={{ maxWidth: "1522px", width: "100%" }}
          spacing={2}
        >
          {Array(lines)
            .fill(null)
            .map((_, index) => (
              <React.Fragment key={index}>{gridItem}</React.Fragment>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

export { SkeletonSubject };
