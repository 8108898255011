import React, { useEffect, useState } from "react";
import { useResource } from "hooks/useResource";

import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { Box, Typography } from "@mui/material";
import { SupportArticleCategoryLevel } from "./SupportArticleCategoryLevel";
import { SupportArticleCategoryDto } from "dto/support/supportarticlecategory.dto";
import { SupportArticleList } from "./SupportArticleList";

type Props = {
  currentRoute: any;
  level: number;
  obj: SupportArticleCategoryDto;
  hasChildren?: boolean;
};

const SupportArticleCategoryItem: React.FC<Props> = ({
  currentRoute,
  level,
  obj,
  hasChildren,
  ...props
}) => {
  const [nextLevel, setNextLevel] = useState<number>(1);
  const [maxLevel, setMaxLevel] = useState<number>(1);

  const { CC } = useResource();

  useEffect(() => {
    let ml: number = parseInt(CC("MAXLEVEL_Category_Support_Article", "5"));
    ml = isNaN(ml) || ml == undefined ? 1 : ml;
    setMaxLevel(ml);
  }, []);

  useEffect(() => {
    setNextLevel(level + 1);
  }, [level]);

  const processDetail = () => {
    if (!obj) return null;

    return (
      <Box>
        <Typography className="title-support">
          {ComponentCommonTools.processObjectField(obj, "name")}
        </Typography>

        <SupportArticleList
          currentRoute={currentRoute}
          idCategory={obj.id ?? ""}
        />
      </Box>
    );
  };

  const processChildren = () => {
    if (!hasChildren) return null;
    if (nextLevel > maxLevel) return null;

    if (!obj) return null;

    return (
      <Box className={"section" + level}>
        <SupportArticleCategoryLevel
          currentRoute={currentRoute}
          level={nextLevel}
          idparent={obj.id ?? ""}
        />
      </Box>
    );
  };

  return (
    <Box className="item">
      {processDetail()}
      {processChildren()}
    </Box>
  );
};

export { SupportArticleCategoryItem };
