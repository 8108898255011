import React from "react";
import { MyNavBt } from "components/elements/button/MyNavBt";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from '@mui/material/Typography';
import { CommonTools } from 'tools/utils/commontools';



const MyBreadcrumbs = ({
    childs,
    mainObject,
    ...props }) => {
    const mainUrl = CommonTools.generateMainUrl(mainObject);
    const processItem = (item, i) => {
        var key = "breadcrumbs_" + i;
        return item.url ? (
            <MyNavBt
                href={item.url}
                underline="hover"
                key={key}
                _mainurl={mainUrl}
                color="text.secondary"

            >
                {item.label}
            </MyNavBt>
        ) : (


            <Typography key={key} color="text"  >{item.label}</Typography>
        );
    };

    return (
        <Breadcrumbs
            separator="›"
            className="breadcrumbs"
            aria-label="breadcrumb"
            color="text.disabled"
        >
            {childs.map((item, i) => {
                return processItem(item, i);
            })}
        </Breadcrumbs>
    );
};

export { MyBreadcrumbs };
