import UserDto from 'dto/user/user.dto';
import Idto from 'interfaces/idto.interface';

export class StudentDto implements Idto {
    
    id?: string;
    iduser?: string;
    status?: number;
    idssociallink?: string[];
    bio?: string;
    idtypestudies?: string;
    institution?: string;
    notificationtype1?: boolean;
    notificationtype2?: boolean;
    notificationtype3?: boolean;
    showprofile?: boolean;
    showcourses?: boolean;
    email?: string;
    status_name?: string;
    name?: string;
    surname?: string;
    typestudies?:any;
    numberofcourses?: number;
    user?: UserDto;
    fullname?: string;
    fullurl?: string;
  }

  export class PostStudentDto implements Idto {
    
    iduser?: string;
    status?: number;
    idssociallink?: string[];
    bio?: string;
    idtypestudies?: string;
    institution?: string;
    notificationtype1?: boolean;
    notificationtype2?: boolean;
    notificationtype3?: boolean;
    showprofile?: boolean;
    showcourses?: boolean;
    numberofcourses?: number;
  }