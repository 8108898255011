import React from "react";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { FormControlLabel, Radio } from "@mui/material";

import { SupportArticleCategoryDto } from "dto/support/supportarticlecategory.dto";


type Props = {
  item: SupportArticleCategoryDto;
};
const CategorySearchCodeFilterItem: React.FC<Props> = ({ item }) => {
  const processCode = () => {
    if (!item) return "";
    if (!item.hasOwnProperty("maincode")) return "";
    if (!item.maincode) return "";
    return item.maincode;
  };

  const processNumOdArticles = () => {
    if (!item) return null
    if (!item.hasOwnProperty("numberofarticles")) return null
    if (!item.numberofarticles) return null
    return <>({item.numberofarticles})</>;
  };
  return (
    <FormControlLabel
      value={processCode()}
      control={<Radio />}
      label={
        <>
          {ComponentCommonTools.processObjectField(item, "name")}{" "}
          {processNumOdArticles()}
        </>
      }
    />
  );
};

export { CategorySearchCodeFilterItem };
