import React, { useEffect, useState } from "react";
import { useResource } from "hooks/useResource";
import { BlogCategoryDto } from "dto/static/blogcategory.dto";
import { BlogCategoryLevel } from "./BlogCategoryLevel";
import Box from "@mui/material/Box";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { Stack } from "@mui/material";


type BlogCategoryItemProps = {
    currentRoute: any;
    level: number;
    obj: BlogCategoryDto;
    hasChildren?: boolean;
    setBlogCategoryId?: any
    setIdTypeBlogCategory:any
    idTypeBlogCategory: string;
}

const BlogCategoryItem: React.FC<BlogCategoryItemProps> = ({
    currentRoute,
    level,
    obj,
    hasChildren,
    setBlogCategoryId,
    setIdTypeBlogCategory,
    idTypeBlogCategory,
    ...props
}) => {
    const [nextLevel, setNextLevel] = useState<number>(1);
    const [maxLevel, setMaxLevel] = useState<number>(1);
    const [isChildsShowed, setIsChildsShowed] = useState<boolean>(true);

    const { CC } = useResource()

    useEffect(() => {
        let ml: number = parseInt(CC("MAXLEVEL_Category_Blog", "6"));
        ml = isNaN(ml) || ml == undefined ? 1 : ml;
        setMaxLevel(ml);

    }, []);

    useEffect(() => {
        setNextLevel(level + 1);
    }, [level]);

    // useEffect(() => {
    //     setIsChildsShowed(false);
    // }, [obj]);


    const handleSelect = (id?: string) => {
        if (!id) return;
        setBlogCategoryId(id)
        setIdTypeBlogCategory('')
    }

    const processDetail = () => {
        if (!obj) return <></>;
        if (!obj.hasOwnProperty('id')) return <></>;
        if (!obj.id) return <></>;
        return (
            <Box onClick={() => { handleSelect(obj.id) }}>
                <Stack direction='row' spacing={2}>
                    {/* <Box>{processIcon()}</Box> */}
                    {ComponentCommonTools.processObjectField(obj, 'name')}

                </Stack>
            </Box>);
    };

  
    // const processIcon = () => {
    //     if (!obj) return <></>;
    //     if (!obj.hasOwnProperty('icon')) return <></>;
    //     if (!obj.icon) return <></>;
    //     return ComponentCommonTools.getFileBlockSVG(obj.icon);
    // }
    const processChilds = () => {
        if (!hasChildren) return <></>;
        if (nextLevel > maxLevel) return <></>;
        // if (!isChildsShowed) return <></>;
        if (!obj) return <></>;

        return (
            <Box className={"section" + level} ml={3}>
                <Box>
                    <BlogCategoryLevel
                        currentRoute={currentRoute}
                        level={nextLevel}
                        idparent={obj.id ?? ""}
                        setBlogCategoryId={setBlogCategoryId}
                        setIdTypeBlogCategory={setIdTypeBlogCategory}
                        idTypeBlogCategory={idTypeBlogCategory}
                    /></Box>
            </Box>
        );
    };



    return (
        
            <Box className="item"
                onMouseOut={() => {
                    setIsChildsShowed(false);
                }}
                onMouseOver={() => {
                    setIsChildsShowed(true);
                }}

            >
                {processDetail()}
                {/* {processBts()} */}
                {processChilds()}
            </Box>
        
    );

};

export { BlogCategoryItem };
