import React, { useEffect, useState } from "react";
import { useResource } from "hooks/useResource";

import {PageService} from "services/static/page.service";
import { PageDto } from "dto/static/page.dto";

import { HtmlTag } from "components/elements/display/HtmlTag";
import { Skeleton } from "@mui/material";


const pageService = new PageService();

type StaticInfoPageProps = {
    idPage?: string;
    cacheKeyName: string;
}

const StaticInfoPage: React.FC<StaticInfoPageProps> = ({
    idPage,
    cacheKeyName,
    ...props
}) => {
    const { saveCache, getCache } = useResource();
    const cacheKey = "StaticInfoPage" + cacheKeyName;
    const [isLoading, setIsLoading] = useState(true);
    const [obj, setObject] = useState<PageDto | null>(null);


    const loadObject = (_obj: any) => {


        setObject(_obj.obj);
        setIsLoading(false);
        saveCache(_obj.obj, cacheKey);
    }

    const getObject = async () => {
        const id = idPage ? idPage : "-1";

        const cd = getCache(cacheKey);
        if (cd !== false) {
            setObject(cd);
            setIsLoading(false);
            return;
        }

        setIsLoading(true);

        pageService.get(id, loadObject);
    };

    useEffect(() => {
        if(!idPage) return;
        getObject();
    }, [idPage]);

    return isLoading ? (
        <Skeleton height={200} />
    ) : (
        <HtmlTag content={obj?.content} />
    );
};

export { StaticInfoPage };
