import React, { useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hooks/useResource";
import { logger } from "tools/utils/logger";

import { Box } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import { RouteTools } from "tools/utils/routetools";
import { useAuth } from "hooks/useAuth";
import { ContactFormDialog } from "./ContactFormDialog";

const ContactBlock: React.FC<PageComponentProps> = ({ currentRoute }) => {
  const { LL } = useResource();
  const { user, setNextLocation } = useAuth();

  const [openForm, setOpenForm] = useState<boolean>(false);

  const processDialog = () => {
    if (!openForm) return null;
    return <ContactFormDialog open={openForm} setOpen={setOpenForm} />;
  };

  const handleOpen = () => {
    if (!user) {
      //logger("ContactBlock", currentRoute);
      const url = currentRoute
        ? currentRoute.url
          ? currentRoute.url
          : "/"
        : "/";
      setNextLocation(url);
      RouteTools.setHistory("/login", {});
    } else {
      setOpenForm(true);
    }
  };

  return (
    <Box>
      <MyButton onClick={handleOpen} variant="contained" color="primary">
        {LL("Contact_Us")}
      </MyButton>
      {processDialog()}
    </Box>
  );
};

export { ContactBlock };
