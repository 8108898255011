import * as React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

type Props ={
  lines?: number;
  width?: number;
}


const SkeletonFilterBox: React.FC<Props> = ({ lines }) => {
  
  const gridItem = (
    <Skeleton
      variant="text"
      sx={{
        fontSize: "1.25rem",
        width: "70%",
      }}
    />
  );

  return (
    <Box sx={{ width: "100%" }} className="">
      <Box
        sx={{
          width: "100%",
          display: { xs: "none", md: "flex" },
          justifyContent: "center",
          alignContent: "center",
          margin: "auto",
          flexDirection: "column",
        }}
      >
        {Array(lines)
          .fill(null)
          .map((_, index) => (
            <React.Fragment key={index}>{gridItem}</React.Fragment>
          ))}
      </Box>
    </Box>
  );
};

export { SkeletonFilterBox };
