import RequestListDTO from "dto/app/requestlist.dto";
import GeneralAxiosRepository from "./general.repository";
import IGeneralRepository from "interfaces/newirepository.interface";
import Idto from "interfaces/idto.interface";

const {  REACT_APP_URLPREFIX } = process.env;

export class GlobalGeneralRepository
  extends GeneralAxiosRepository
  implements IGeneralRepository
{
  protected mainUrl: string;

  constructor(mainUrl: string, withPrefix?: boolean) {
    if (withPrefix === undefined) withPrefix = true;
    super();
    this.mainUrl =
      // (REACT_APP_API_SERVER ?? "") +
      (REACT_APP_URLPREFIX && withPrefix ? REACT_APP_URLPREFIX : "") +
      "/" +
      mainUrl;
  }
  patch(id: string, cb?: any, data?: Idto, cbparameters?: any): Promise<any> {
    let url = `${this.mainUrl}/${id}`;
    return this.patchAxios(url, data, cb, cbparameters);
  }

  get = async (id: string, cb?: any, cbParams?: any, idLanguage?: string) => {
    let url = `${this.mainUrl}/${id}`;
    if (idLanguage) url = url.concat(`?idlanguage=${idLanguage}`);

    return await this._get(url, cb, cbParams);
  };

  getList = async (cb?: any, cbParams?: any, data?: RequestListDTO) => {
    const url = `${this.mainUrl}`;
    return await this._getList(url, cb, cbParams, data);
  };

  add = async (cb?: any, cbParams?: any, data?: any) => {
    const url = `${this.mainUrl}`;
    return await this.postAxios(url, cb, data, cbParams);
  };

  update = async (id: string, cb?: any, data?: any, cbParams?: any) => {
    const url = `${this.mainUrl}/${id}`;
    return await this.putAxios(url, cb, data, cbParams);
  };

  delete = async (id: string, cb?: any, cbParams?: any) => {
    const url = `${this.mainUrl}/${id}`;
    return await this.deleteAxios(url, cb, cbParams);
  };

  addMultipart = async (cb?: any, cbParams?: any, data?: any) => {
    const url = `${this.mainUrl}`;
    return await this.postAxiosMultipart(url, cb, data, cbParams);
  };

  updateMultipart = async (
    id: string,
    cb?: any,
    cbParams?: any,
    data?: any
  ) => {
    const url = `${this.mainUrl}/${id}`;
    return await this.putAxiosMultipart(url, cb, data, cbParams);
  };
}
